import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { updateLocalUser } from "../../redux/actions/userActions"
import { getAllTypes } from "../../const"



export const IntervalSelector = () =>
{
  const dispatch = useDispatch()
  const chartInterval = useSelector((state: ApplicationState) => state.userStore.user.chartInterval)

    return(
      <Select size="small" sx={{ mr: 2, color: "white" }} value={chartInterval || "1m"} onChange={(e: any) => dispatch(updateLocalUser({ chartInterval: e.target.value }))}>
          {getAllTypes().map((interval, key) => <MenuItem value={interval} key={key}>{interval}</MenuItem>)}
      </Select>
    )


}