
// MUI
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

// Custom
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import { SellSection } from './SellSection/SellSection';
import { Transition } from '../../../animations/Transition';
import { BuySection } from './BuySection/BuySection';

interface BuySellModalProps
{
  open: boolean
  handleClose: () => void
  tradingPair: string
  maxSell: number
  maxBuy?: number
  waitForBearSignal: boolean
  side: "buy" | "sell"
}

export const BuySellModal = ({ open, handleClose, tradingPair, maxSell, maxBuy, waitForBearSignal, side }: BuySellModalProps) =>
{
  const [ tabIndex, setTabIndex ] = useState(side)
  return(
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>Confirm {tabIndex} ({tradingPair})</DialogTitle>

    <Tabs value={tabIndex} onChange={(_, index) => setTabIndex(index)}>
      <Tab value="buy" label="Buy"></Tab>
      <Tab value="sell" label="Sell"></Tab>
    </Tabs>

    { tabIndex === "sell" && <SellSection handleClose={handleClose} maxSell={maxSell} tradingPair={tradingPair} waitForBearSignal={waitForBearSignal} /> }
    { tabIndex === "buy" && <BuySection handleClose={handleClose} maxBuy={maxBuy} tradingPair={tradingPair} /> }

  </Dialog>
  )
}

const styles = {
  loading: {
    marginTop: 16,
    borderRadius: 12,
  },
  checkbox: {
    marginTop: 12
  }
}