import { getSummaryError, getSummarySuccess, getSymbolsError, getSymbolsSuccess, getUserError, getUserSuccess, setUserError, setUserSuccess, UserActions, validateApiError, validateApiSuccess } from "../actions/userActions";
import { takeLatest, put } from 'redux-saga/effects'
import { UserApi } from "../../api/UserApi";
import { Symbol } from "../../interface/Symbol";
import { LogApi } from "../../api/LogApi";

function* getUserProfile(): any
{
  const user = yield new UserApi().getSettings()
  try
  {
    if(user)
    {
      yield put(getUserSuccess(user))
    }
  }
  catch(error)
  {
    yield put(getUserError(error))
  }

}

function* setUserProfile(action: any): any
{
  try
  {
    const user = yield new UserApi().setSettings(action.data)
    yield put(setUserSuccess(user))
  }
  catch(error)
  {
    yield put(setUserError(error))
  }

}

function* getSymbolsWorker(): any
{
  try
  {
    const symbols = yield new UserApi().getSymbols()

    const filtered = symbols?.filter((item: Symbol) => 
        item.symbol.endsWith("USDT") || 
        item.symbol.endsWith("TUSD") ||
        item.symbol.endsWith("BUSD") )

    yield put(getSymbolsSuccess(filtered))
  }
  catch(error)
  {
    yield put(getSymbolsError())
  }

}

function* getSummary(action: { data: { tradingPair: string }}): any
{
  try
  {
    const summary = yield new LogApi().getSummary(action.data.tradingPair)
    yield put(getSummarySuccess(summary))
  }
  catch(error)
  {
    yield put(getSummaryError())
  }

}

function* validateKey()
{
    // if the key is already valid then don't validate it again
    try
    {
      // Will throw if it's invalid
      yield new UserApi().validateApiKey()
      yield put(validateApiSuccess())
    }
    catch(error)
    {
      const errorCast = error as any
      yield put(validateApiError(errorCast?.message?.response?.data?.error))

    }
}


export function* userSaga()
{
  yield takeLatest(UserActions.GET_USER_PROFILE_BEGIN, getUserProfile)
  yield takeLatest(UserActions.SET_USER_PROFILE_BEGIN, setUserProfile)
  yield takeLatest(UserActions.GET_SYMBOLS_BEGIN, getSymbolsWorker)
  yield takeLatest<any>(UserActions.GET_SUMMARY_BEGIN, getSummary)
  yield takeLatest(UserActions.VALIDATE_API_KEY_BEGIN, validateKey)
}