import createTheme from "@mui/material/styles/createTheme";

export const GREEN = "#66BB6A"
export const BLUE = "#64B5F6"
export const RED = "#EF5350"

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  shape: {
    borderRadius: 16
  },
  typography: {
    "fontFamily": `Chakra Petch`,
    "fontWeightLight": 700,
    "fontWeightMedium": 700,
    "fontWeightRegular": 700


  }
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#326fa8',
    },
    secondary: {
      main: '#f50057',
    },
  },
  shape: {
    borderRadius: 16
  },
  typography: {
    "fontFamily": `Chakra Petch`,
    "fontWeightLight": 700,
    "fontWeightMedium": 700,
    "fontWeightRegular": 700
  },

});