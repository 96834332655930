import Grid from '@mui/material/Grid'
import Fab from '@mui/material/Fab';
import CssBaseline from '@mui/material/CssBaseline';
import SellIcon from '@mui/icons-material/SellRounded'

import { useRef, useState } from 'react'
import { useEffect } from 'react'
import { LogApi } from '../api/LogApi'
import { ApplicationState } from '../interface/ApplicationState'
import { ProfitSummary } from '../interface/ProfitSummary'
import { REFRESH_INTERVAL } from '../const'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { OnboardModal } from '../components/modals/Onboard/OnboardModal';
import { TrialOverModal } from '../components/modals/TrialOver/TrialOverModal';
import { ProfitCard } from './dashboardv2/ProfitCard';
import { AssetCard } from './dashboardv2/AssetCard';
import { TotalAssets } from './dashboardv2/TotalAssets';
import { useDispatch, useSelector } from 'react-redux';
import { CurrentFunds } from './dashboardv2/CurrentFunds';
import { ChartV2Section } from './dashboardv2/ChartV2Section';
import { OrdersV2Section } from './dashboardv2/OrdersV2Section';
import { BuySellModal } from '../components/modals/BuySellModal/BuySellModal';
import { getSummaryBegin, validateApiKey } from '../redux/actions/userActions';

export const DashboardV2 = () =>
{
  const user = useSelector(({ userStore }: ApplicationState) => userStore.user)
  const isGettingUserProfile = useSelector(({ userStore }: ApplicationState) => userStore.isGettingUserProfile)
  const subscriptionStatus = useSelector((state: ApplicationState) => state.billing.subscriptionStatus)
  const summary = useSelector((state: ApplicationState) => state.userStore.summary)
  const isGettingSummary = useSelector((state: ApplicationState) => state.userStore.isGettingSummary)
  const dispatch = useDispatch()
  const refreshRef = useRef<NodeJS.Timer>()

  const [ profitSummary, setProfitSummary ] = useState<ProfitSummary[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)
  const [ showSellModal, setShowSellModal ] = useState(false)

  const isApiKeyValid = useSelector((state: ApplicationState) => state.userStore.isApiKeyValid)
  const apiKeyError = useSelector((state: ApplicationState) => state.userStore.apiKeyError)

  const getProfitSummary = async () =>
  {
    try
    {
      const logApi = new LogApi()
      const profitSummaryData = await logApi.getProfitSummary()
      setProfitSummary(profitSummaryData)
    }
    catch(error)
    {
      console.error("Error getting profit summary")
    }

  }

  useEffect(() => {

    getAllDashboardData()
    setRefresh()

    return function()
    {
      clearInterval(refreshRef.current)
    }

  }, [ ])

  useEffect(() => {

    getAllDashboardData()

  }, [ user.tradingPair ])

  const setRefresh = () =>
  {
    refreshRef.current = setInterval(() => {

      getAllDashboardData()

    }, REFRESH_INTERVAL) // Refresh every 10 mins
  }

  const getAllDashboardData = async () =>
  {
    if(user.tradingPair) dispatch(getSummaryBegin(user.tradingPair, true))
    if(!isApiKeyValid) dispatch(validateApiKey())

    setLoading(true)
    await getProfitSummary()
    setLoading(false)
  }

  return(

    <div style={{ height: "93vh", margin: 12 }}>
    <CssBaseline />

    { subscriptionStatus?.mode === 'free' && <TrialOverModal open handleClose={() => {} }/>}
    { !isGettingUserProfile && !loading && !isGettingSummary && !isApiKeyValid && <OnboardModal open handleClose={() => {}} /> }
    
      { isApiKeyValid && <Grid container spacing={3}>

        <Grid container spacing={2}>

          {/* Row 1 */}
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <ProfitCard loading={loading || isGettingSummary} profitSummary={profitSummary} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <AssetCard 
             assetName={summary?.tradingPairSummary.asset || "Unknown"}
             summary={summary}
             summaryItem={summary?.tradingPairSummary} 
             loading={loading || isGettingSummary} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container direction="column" spacing={2}>

            <Grid item>
              <TotalAssets loading={loading|| isGettingSummary} summary={summary} user={user} />
            </Grid>
          
            <Grid item>
              <CurrentFunds loading={loading || isGettingSummary} summary={summary} user={user} />
            </Grid>

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <ChartV2Section loading={loading || isGettingSummary} user={user}  />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <OrdersV2Section marketId={user.tradingPair} />
          </Grid>


        </Grid>

      { showSellModal && summary && user && user.tradingPair && <BuySellModal maxBuy={1000} side="sell" waitForBearSignal maxSell={Number(summary?.tradingPairSummary.estValueInUsd.toFixed(2))} open={showSellModal} handleClose={() => setShowSellModal(false)} tradingPair={user.tradingPair} /> }

      <Fab variant="extended" style={styles.fab} onClick={() => setShowSellModal(true)}>
        <SellIcon sx={{ mr: 1 }} /> Sell
      </Fab>

      <Snackbar
      open={!!apiKeyError} 
      onClose={() => {}} autoHideDuration={3000}>
        <Alert variant='filled' severity="error" >
        {apiKeyError}
      </Alert>
      </Snackbar>

      </Grid> }
    </div>
  
  )

}

export const styles = {
  card: {
    height: 220,
    padding: 16,
  },
  fab: { 
    position: "fixed" as "fixed",
    margin: 0, 
    top: "auto", 
    right: 32, 
    bottom: 32, 
    left: "auto" 
  },
  loading: {
    float: "right" as "right"
  },
}