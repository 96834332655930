import { Line, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, XAxis} from 'recharts';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { BollingerBandItem } from '../../interface/BollingerBandItem';

interface BollingerBandsChartProps
{
  chartInterval: string;
  tradingPair: string;
}


export const BollingerBandsChart = ({ chartInterval, tradingPair  }: BollingerBandsChartProps) =>
{

  const [ bb, setBB ] = useState<BollingerBandItem[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  useEffect(() =>
  {
    getBollingerBands(!bb)
  }, [chartInterval])

  const getBollingerBands = async (load: boolean) =>
  {
    if(tradingPair && chartInterval)
    {
      const logApi = new LogApi()
      load && setLoading(true)
      const bb = await logApi.getBollingerBands(tradingPair, chartInterval)
      setBB(bb)
      load && setLoading(false)
    }

  }

  return(
  !loading ? <div style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Bollinger Bands Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={450}>
      <ComposedChart
        width={100}
        height={500}
        data={bb || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis tickFormatter={(tick) => `$${tick}`} domain={['dataMin', 'dataMax']} />
        <XAxis dataKey="timestamp" tickFormatter={(ts: number) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100} />
        
        <Legend formatter={(e) => {
          if(e === "upper") return `Upper`
          if(e === "middle") return `Middle`
          if(e === "lower") return "Lower"
          if(e === "close") return "Close Price"
          if(e === "pb") return "PB"

        }} />
        <Line type="monotone" dataKey="upper" stroke="#00BCD4" dot={false} strokeWidth={3} />
        <Line type="monotone" dataKey="middle" stroke="#E91E63" dot={false} strokeWidth={2} />
        <Line type="monotone" dataKey="lower" stroke="#00BCD4" dot={false} strokeWidth={3} />
        <Line type="monotone" dataKey="close" stroke="#FDD835" dot={false} strokeWidth={2} />

        <Tooltip formatter={(tick) => `$${Number(tick).toFixed(2)} USD`} labelStyle={{ color: "grey"}} labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`} />

      </ComposedChart>
      </ResponsiveContainer>
    </div> : <div style={styles.loader}><CircularProgress /></div>
  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 10,
    padding: 12,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}