
import { useState } from 'react';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'

import { Stripe } from 'stripe'

// Custom
import { BillingApi } from '../../api/BillingApi';
import { AddCreditCard } from './AddCreditCard';
import { AxiosError } from 'axios';
import { Transition } from '../../animations/Transition';

interface AddCardModalProps
{
  open: boolean
  handleClose: () => void
}

export const AddCardModal = ({ open, handleClose }: AddCardModalProps) =>
{

  // Payment details
  const [ cvc, setCVC ] = useState("")
  const [ cardNumber, setCardNumber ] = useState("")
  const [ expiry, setExpiry ] = useState("")
  const [ loading, setLoading ] = useState(false)
  const [ addSuccess, setAddSuccess ] = useState<Stripe.Response<Stripe.Customer> | undefined>()
  const [ addCardError, setAddCardError ] = useState<AxiosError | undefined>()


  const areAllInputsValid = () =>
  {
    if(cardNumber.length === 19 && expiry.length === 7 && cvc.length === 3) return true
    return false
  }

  const updateCard = async () =>
  {
    try
    {
      setAddCardError(undefined)

      const expMonth = expiry.split("/")[0].trim()
      const expYear = expiry.split("/")[1].trim()
  
      setLoading(true)
      const response = await new BillingApi().addCreditCard(cvc, expMonth, expYear, cardNumber)
      setLoading(false)
      setAddSuccess(response)
      handleClose()
    }
    catch(error)
    {
      setLoading(false)
      setAddCardError(error as AxiosError)
    }

  }

  const addSubscriptionErrorResponse = () =>
  {
    try
    {
      // @ts-ignore
      return addCardError.message.response.data.error.message
    }
    catch(error)
    {
      return ""
    }
  }



  return(
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>Add new card</DialogTitle>
    <DialogContent>

    <AddCreditCard setCardNumber={setCardNumber} cardNumber={cardNumber} cvc={cvc} setCVC={setCVC} expiry={expiry} setExpiry={setExpiry} />

    { addCardError && <Alert severity="error">{addSubscriptionErrorResponse()}</Alert> }

    </DialogContent>
    <DialogActions style={{ display: 'flex', justifyContent: 'space-between'}}>
      <Button startIcon={<CloseIcon />} onClick={handleClose}>Close</Button>
      <Button disabled={!areAllInputsValid()} startIcon={loading ? <CircularProgress size={16} /> : <AddIcon />} onClick={updateCard}>Add</Button> 

    </DialogActions>

  </Dialog>
  )
}