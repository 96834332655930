import { useState } from "react"
import { useEffect } from "react"
import { BtcMarkets } from "../../../api/BtcMarkets"
import { QueryOrderResult } from "../../../interface/QueryOrderResult"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';

import DialogTitle from '@mui/material/DialogTitle';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import Avatar from '@mui/material/Avatar';
import { Transition } from "../../../animations/Transition";


interface OrderDetailsModalProps
{
  open: boolean
  orderId: number
  marketId: string
  handleClose: () => void
}


export const OrderDetailsModal = ({ open, handleClose, orderId, marketId }: OrderDetailsModalProps) =>
{
  const [ orderDetails, setOrderDetails ] = useState<QueryOrderResult | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)
  useEffect(() => {

    getOrderDetails()

  }, [])

  const getOrderDetails = async () =>
  {
    try
    {
      setLoading(true)
      const response = await new BtcMarkets().getOrder(marketId, orderId)
      setOrderDetails(response)
      setLoading(false)
    }
    catch(error)
    {
      setLoading(false)
    }
  }


  return(
    <Dialog
    open={open}
    TransitionComponent={Transition}
    keepMounted
    fullWidth
    style={styles.dialog}
    maxWidth="md"
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description">
  <DialogTitle>Order Details</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-slide-description">

    </DialogContentText>

    {!loading ? Object.keys(orderDetails || {}).map((orderItem, key) => {

      return(
       <ListItem key={key}>
        <ListItemAvatar>
          <Avatar>
            {orderItem[0].toUpperCase()}
          </Avatar>
        </ListItemAvatar>
        {/* @ts-ignore */}
        <ListItemText primary={orderItem} secondary={orderDetails[orderItem]} />
      </ListItem>
       
      )

    }) : <div style={styles.spinner}><CircularProgress /></div>}

   

  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>Close</Button>
  </DialogActions>
</Dialog>
  )
}

const styles = {
  spinner: {
    flex: 1,
    display: 'flex' as 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
  },
  dialog: {
    height: 600,
  }
}