import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import { BINANCE_COM_ENDPOINT, BINANCE_TESTNET_ENDPOINT, BINANCE_US_ENDPOINT, KRAKEN_BASE_URL } from "../../../const";
import { BotExchange } from '../../../interface/BotExchange';

interface UserEndpointSectionProps
{
  onChangeEndpoint: (endpoint: string, exchange: BotExchange) => void
  exchange: BotExchange
}

export const UserExchangeSection = ({ onChangeEndpoint, exchange }: UserEndpointSectionProps) =>
{
  return(
    <Grid container spacing={2}>
      <Grid item>
      <Button variant={exchange === BotExchange.binanceTestnet ? "contained" : "outlined"} value={BINANCE_TESTNET_ENDPOINT} key={1} onClick={() => onChangeEndpoint(BINANCE_TESTNET_ENDPOINT, BotExchange.binanceTestnet)}>TestNet</Button>
      </Grid>

      <Grid item>
      <Button variant={exchange === BotExchange.binance ? "contained" : "outlined"} value={BINANCE_COM_ENDPOINT} key={2} onClick={() => onChangeEndpoint(BINANCE_COM_ENDPOINT, BotExchange.binance)}>Binance.com</Button>
      </Grid>

      <Grid item>
      <Button variant={exchange === BotExchange.binanceUS ? "contained" : "outlined"} value={BINANCE_US_ENDPOINT} key={3} onClick={() => onChangeEndpoint(BINANCE_US_ENDPOINT, BotExchange.binanceUS)}>Binance.US</Button>
      </Grid>

      <Grid item>
      <Button variant={exchange === BotExchange.gateIo ? "contained" : "outlined"} key={4} onClick={() => onChangeEndpoint("", BotExchange.gateIo)}>Gate.IO</Button>
      </Grid>

      <Grid item>
      <Button variant={exchange === BotExchange.kraken ? "contained" : "outlined"} key={4} onClick={() => onChangeEndpoint(KRAKEN_BASE_URL, BotExchange.kraken)}>Kraken</Button>
      </Grid>

    </Grid>
  )
}

const styles = {
  container: {
    display: 'flex' as 'flex',
    flex: 1,

  }
}