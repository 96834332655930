import Dialog from "@mui/material/Dialog"
import { Transition } from "../../../animations/Transition"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"

import CloseIcon from "@mui/icons-material/Close"
import StopIcon from "@mui/icons-material/StopCircle"
import DeleteIcon from "@mui/icons-material/DeleteForever"


import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../../interface/ApplicationState"
import Typography from "@mui/material/Typography"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import TextField from "@mui/material/TextField"

import { AlertColor } from "@mui/material"
import { calculateGridLevels } from "../util/calculateGridLevels"
import { green, red } from "@mui/material/colors"
import { getStepPrice } from "./helper/getStepPrice"
import { ConfirmStopModal } from "./ConfirmStopModal"
import { GridApi } from "../../../api/GridApi"
import { clearCurrentBot, getBotConfigs, setCurrentBotConfig } from "../../../redux/reducers/grid/gridReducer"
import { ConfirmDeleteBotModal } from "./ConfirmDeleteBotModal"
import { GridLevelsPreview } from "../table/GridLevelsPreview"

interface EditBotConfigProps
{
  open: boolean
  onClose: () => void
}

export const EditBotConfig = ({ onClose, open }: EditBotConfigProps) => {

  const botConfig = useSelector((state: ApplicationState) => state.grid.botConfig)
  const marketData = useSelector((state: ApplicationState) => state.grid.marketData)
  const dispatch = useDispatch()

  // Response
  const [ stopping, setStopping ] = useState(false)
  const [ deleting, setDeleting ] = useState(false)

  const [ confirmStop, setConfirmStop ] = useState(false)
  const [ confirmDelete, setConfirmDelete ] = useState(false)

  const [ snack, setSnack ] = useState({ message: "", color: "" })

  const handleClose = () =>
  {
    onClose()
  }

  const getMarketDetails = () => {
    return marketData.find(m => m.symbol === botConfig?.tradingPair)
  }

  const handleStopBot = async () => {

    // Hide the confirm modal
    setConfirmStop(false)
    // Show Progress
    setStopping(true)
    // And Info progress
    setSnack({ color: "info", message: "Stopping Bot...Removing pending limit orders..."})

    try
    {
      if(!botConfig?._id) return
      const response = await new GridApi().stopBot(botConfig?._id)

      if(response?.result)
      {
        dispatch(setCurrentBotConfig({ ...botConfig, isEnabled: false }))
        setSnack({ color: "success", message: "Stopped Bot OK"})
        dispatch(getBotConfigs())
      }
    }
    catch(error)
    {
      setSnack({ color: "error", message: "Error stopping bot"})
    }
    
    setStopping(false)

  }

  const handleDeleteBot = async () => {

    // Hide the confirm modal
    setConfirmStop(false)
    // Show Progress
    setDeleting(true)
    // And Info progress
    setSnack({ color: "info", message: "Deleting Bot"})

    try
    {
      if(!botConfig?._id) return
      const response = await new GridApi().remove(botConfig?._id)

      if(response?.result)
      {
        // Clear the current bot from Redux
        dispatch(clearCurrentBot())
        // Show status OK
        setSnack({ color: "success", message: "Deleted Bot OK"})
        // Callback so we can reload the list
        dispatch(getBotConfigs())
        // Close the edit modal
        onClose()
      }
    }
    catch(error)
    {
      setSnack({ color: "error", message: "Error deleting bot"})
    }
    
    setDeleting(false)

  }

  const symbolData = getMarketDetails()

  const { investmentPerGrid, priceSpacing, totalProfit, totalProfitPercentage } = calculateGridLevels(botConfig?.numberOfGrids!!, botConfig?.maxInvest!!, botConfig?.priceRangeHigh!!, botConfig?.priceRangeLow!!, Number(symbolData?.lastPrice))

  const steps = getStepPrice(botConfig!!, symbolData?.lastPrice!!)

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} fullWidth maxWidth="xl">

      <DialogTitle>Grid Bot Details [{botConfig?.tradingPair}]</DialogTitle>

     <DialogContent>

      <Grid container direction="row" spacing={3}>

        <Grid item container xs={12} sm={12} md={12} lg={6} xl={6}>

          <GridLevelsPreview gridLevels={botConfig?.gridLevels || []}/>

        </Grid>

      {/* Column 2 */}
      <Grid item container direction="column" spacing={3} xs={12} sm={12} md={12} lg={6} xl={6}>

      <Grid item>
        <Typography variant="body2" fontWeight={700} fontSize={12}>Bot Name</Typography>
        <TextField fullWidth value={botConfig?.name} />
      </Grid>

<Grid item>
  <Typography fontWeight={800}>Market Price of Asset: ${symbolData?.lastPrice}</Typography>
</Grid>

<Grid item spacing={3}>

<Stepper activeStep={1} alternativeLabel>
{steps.map((step, index) => {
  const labelProps: { optional?: React.ReactNode; error?: boolean } = {};
  if (step.message) {
    labelProps.optional = (
      <Typography variant="caption" color={step.color === "success" ? green[500] : red[500]}>
        { step.message}
      </Typography>
    );
    labelProps.error = step.color === "error" ? true : false;
  }

  return (
    <Step key={index}>
      <StepLabel {...labelProps}>{step.label}</StepLabel>
    </Step>
  );
})}
</Stepper>
</Grid>

<Grid item>
  <div style={styles.textSection}>
    <Typography fontWeight={800}>Price range is: ${Number(botConfig?.priceRangeHigh) - Number(botConfig?.priceRangeLow)}</Typography>
  </div>
</Grid>

<Grid item>
  <Typography variant="body2" fontWeight={700} fontSize={12}></Typography>
  <div style={styles.textSection}>
    <Typography fontWeight={800}>Number of grids: {botConfig?.numberOfGrids}</Typography>
  </div>
</Grid>

<Grid item>
  <div style={styles.textSection}>
    <Typography fontWeight={800}>Max investment: ${botConfig?.maxInvest.toFixed(2)}</Typography>
  </div>
</Grid>

<Grid item >
  <div style={styles.textSection}>
    <Typography fontWeight={800}>Buy/Sell amount per grid: ${investmentPerGrid?.toFixed(2)}</Typography>
  </div>
</Grid>

<Grid item >
  <div style={styles.textSection}>
    <Typography fontWeight={800}>Price spacing: ${priceSpacing}</Typography>
  </div>
</Grid>

<Grid item >
  <div style={styles.textSection}>
    <Typography fontWeight={800}>Projected Profit on first iteration (fee's deducted): ${totalProfit.toFixed(2)} ({totalProfitPercentage?.toFixed(2)}%)</Typography>
  </div>
</Grid>

      </Grid>

      </Grid>



     </DialogContent>

     <DialogActions>
      <Button color="secondary" onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
      <Box flex={1} />
      <Button color="secondary" onClick={() => setConfirmDelete(true)} startIcon={<DeleteIcon />} disabled={botConfig?.isEnabled || deleting}>{deleting ? "Deleting..." : "Delete"}</Button>
      <Button color="secondary" onClick={() => setConfirmStop(true)} startIcon={<StopIcon />} disabled={stopping || !botConfig?.isEnabled}>{stopping ? "Stopping..." : "Stop Bot"}</Button>

     </DialogActions>

     <Snackbar open={!!(snack.message && snack.color)}>
      <Alert variant="filled" color={snack.color as AlertColor}>{snack.message}</Alert>
     </Snackbar>

     { confirmStop && <ConfirmStopModal open={confirmStop} onClose={() => setConfirmStop(false)} onConfirm={handleStopBot} marketPrice={Number(symbolData?.lastPrice) || 0} />}
     { confirmDelete && <ConfirmDeleteBotModal open={confirmDelete} onClose={() => setConfirmDelete(false)} onConfirm={handleDeleteBot} />}

    </Dialog>
    
  )
}

const styles = {
  textSection: {
    border: "1px solid grey", borderRadius: 12, padding: 12
  }
}