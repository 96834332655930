import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import { blue, purple } from "@mui/material/colors"
import Typography from "@mui/material/Typography"
import MoneyIcon from '@mui/icons-material/Money';
import MoreVert from '@mui/icons-material/MoreVert';
import { Summary } from "../../interface/Summary";
import { ProfitSummary } from "../../interface/ProfitSummary";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";

interface ProfitCardProps
{
    loading: boolean
    profitSummary: ProfitSummary[] | undefined
}

const KEY_PROFIT_TIMEFRAME_INDEX = "KEY_PROFIT_TIMEFRAME_INDEX"

export const ProfitCard = ({ loading, profitSummary }: ProfitCardProps) =>
{
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [ item, setItem ] = useState<ProfitSummary | undefined>(undefined) 

  const handleSelect = (item: ProfitSummary, index: number) => {
    setAnchorEl(null)
    setItem(item)
    localStorage.setItem(KEY_PROFIT_TIMEFRAME_INDEX, String(index))
  }

  useEffect(() => {

    if(profitSummary)
    {
      const indexValue = localStorage.getItem(KEY_PROFIT_TIMEFRAME_INDEX)
      setItem(profitSummary[Number(indexValue) || 0])
    }

  }, [profitSummary])

  return (
    <Card style={{ height: 250, padding: 16, position: "relative" }} elevation={3}>
    
    { loading ? <>

      <Skeleton height={30} width={30} variant="circular" /> 
      <Skeleton height={20} width="70%" /> 
      <Skeleton height={30} width="100%" /> 

    </>
    : <>
    
    <MoneyIcon fontSize="large" />
    <Typography fontSize={32}>${item?.profit || 0} Profit</Typography>
    <Typography fontSize={24} color="textSecondary">{item?.time}</Typography>

    <div style={{ position: "absolute", top: 16, right: 16 }}>
      <Button size="large" variant="outlined" sx={{ mr: 1 }} endIcon={<MoreVert />} onClick={(e) => setAnchorEl(e.currentTarget)}>{item?.time}</Button>
       <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        { !loading && profitSummary?.map((item, key) => <MenuItem onClick={() => handleSelect(item, key)} key={key} value={item.profit}>{item.time}</MenuItem>)}
      </Menu>
    </div>

    </>}
  </Card>
  )
}