import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import DialogActions from "@mui/material/DialogActions"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import CheckIcon from "@mui/icons-material/Check"

import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../../interface/ApplicationState"
import LinearProgress from "@mui/material/LinearProgress"

import { DialogContentText, Typography } from "@mui/material"
import { GridApi } from "../../../api/GridApi"
import { QueryOrderResult } from "../../../interface/QueryOrderResult"
import { Transition } from "../../../animations/Transition"

interface ConfirmStopModalProps
{
  open: boolean
  marketPrice: number;
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmStopModal = ({ onClose, open, onConfirm, marketPrice }: ConfirmStopModalProps) => {

  const botConfig = useSelector((state: ApplicationState) => state.grid.botConfig)
  const [ loading, setLoading ] = useState(false)
  const [ liveSells, setLiveSells ] = useState<QueryOrderResult[] | undefined>([])
  const [ liveBuys, setLiveBuys ] = useState<QueryOrderResult[] | undefined>([])

  useEffect(() => {

    getLiveOrders()

  }, [])

  const handleClose = () =>
  {
    onClose()
  }

  const getLiveOrders = async () => {

    setLoading(true)

    try
    {
      if(!botConfig?._id) return
      const response = await new GridApi().getLiveOrdersForBot(botConfig?._id)
      setLiveBuys(response?.buyOrders || [])
      setLiveSells(response?.sellOrders || [])
    }
    catch(error)
    {

    }

    setLoading(false)

  }

  const determineCurrentPosition = () => {
    let totalInvested = 0; // Total amount spent on buys
    let totalValue = 0;    // Current total value based on market price
    let dollarProfit = 0;  // Total dollar profit

  
    for (const buy of botConfig?.buyOrderHistory || [])
    {

      // Find the corresponding order
      const correspondingSellOrder = botConfig?.sellOrderHistory.find(sellOrder => sellOrder.buyOrderId === buy.orderId)
      // If sell is not filled - then the buy order is pending and we should check the profit if we close on this position
      if(correspondingSellOrder?.isFilled) continue

      const buyPrice = Number(buy.price);
      const quantity = Number(buy.qty);
  
      // Accumulate total invested and current value
      totalInvested += buyPrice * quantity;
      totalValue += marketPrice * quantity;
    }
  
    // Calculate dollar profit
    dollarProfit = totalValue - totalInvested;
  
    // Calculate percentage profit
    const percentProfit = (dollarProfit / totalInvested) * 100;
  
    return { dollarProfit: dollarProfit?.toFixed(2), percentProfit: percentProfit?.toFixed(2) };
  };

  const { dollarProfit, percentProfit } = determineCurrentPosition()


  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} fullWidth maxWidth="xs">

      <DialogTitle>Confirm Grid Bot Stop [{botConfig?.tradingPair}]</DialogTitle>

     <DialogContent>

      <DialogContentText>Are you sure you want to stop this bot? Your pending buy and sell ordered will be cancelled and funds will return to your base asset.</DialogContentText>

      <div style={styles.textSection}>
        <Typography>Total Buy Orders: {liveBuys?.length}</Typography>
        <Typography>Pending Buy Orders: {liveBuys?.filter(s => s.status === "NEW").length}</Typography>
        <Typography>Filled Buy Orders: {liveBuys?.filter(s => s.status === "FILLED").length}</Typography>
      </div>

      <div style={styles.textSection}>
        <Typography>Total Sell Orders: {liveSells?.length}</Typography>
        <Typography>Pending Sell Orders: {liveSells?.filter(s => s.status === "NEW").length}</Typography>
        <Typography>Filled Sell Orders: {liveSells?.filter(s => s.status === "FILLED").length}</Typography>
      </div>

      <div style={styles.textSection}>
        <Typography>Market Price: ${marketPrice}</Typography>
        <Typography>Profit if all positions are closed now: ${dollarProfit} ({percentProfit || 0}%)</Typography>

      </div>

     </DialogContent>

     <DialogActions>

      <Button color="secondary" onClick={handleClose} startIcon={<CloseIcon />}>Cancel</Button>
      <Box flex={1} />
      <Button color="secondary" onClick={onConfirm} startIcon={<CheckIcon />} disabled={loading} >Yes, Stop This Bot</Button>

     </DialogActions>

     { loading && <LinearProgress />}

    </Dialog>
    
  )
}

const styles = {
  textSection: {
    border: "1px solid grey", borderRadius: 12, padding: 12,
    marginBottom: 12,
    marginTop: 6,

  }
}