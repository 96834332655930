import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import { updateLocalUser } from '../../redux/actions/userActions';
import { Button, FormControlLabel, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { BotExchange } from '../../interface/BotExchange';
import { BINANCE_COM_ENDPOINT, BINANCE_TESTNET_ENDPOINT, BINANCE_US_ENDPOINT, KRAKEN_BASE_URL } from '../../const';


export const ExchangeSelection = () =>
{
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const theme = useTheme()

  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper
  const botExchange = useSelector((state: ApplicationState) => state.userStore.user.botExchange)

  const handleChoose = (exchange: BotExchange) => {
    if(exchange === BotExchange.kraken) dispatch(updateLocalUser({ botExchange: exchange, binanceApiEndpoint: KRAKEN_BASE_URL }))
    if(exchange === BotExchange.binance) dispatch(updateLocalUser({ botExchange: exchange , binanceApiEndpoint: BINANCE_COM_ENDPOINT }))
    if(exchange === BotExchange.binanceUS) dispatch(updateLocalUser({ botExchange: exchange, binanceApiEndpoint: BINANCE_US_ENDPOINT }))
    if(exchange === BotExchange.binanceTestnet) dispatch(updateLocalUser({ botExchange: exchange, binanceApiEndpoint: BINANCE_TESTNET_ENDPOINT }))
    if(exchange === BotExchange.gateIo) dispatch(updateLocalUser({ botExchange: exchange }))
  }

  return(
    <Card style={{ backgroundColor: cardBackGroundColor, borderRadius: 12, margin: 12, padding: 16 }}>

      <ToggleButtonGroup value={botExchange} exclusive onChange={(e: any) => handleChoose(e.target.value)}>
        <ToggleButton value={BotExchange.binance}>Binance</ToggleButton>
        <ToggleButton value={BotExchange.binanceUS}>Binance.US</ToggleButton>
        <ToggleButton value={BotExchange.binanceTestnet}>Binance TestNet</ToggleButton>
        <ToggleButton value={BotExchange.gateIo}>Gate.io</ToggleButton>
        <ToggleButton value={BotExchange.kraken}>Kraken</ToggleButton>

      </ToggleButtonGroup>

  </Card>

  )
}