import { LineChart, Line, YAxis, Tooltip, Legend, ResponsiveContainer, XAxis } from 'recharts';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { MACDItem } from '../../interface/MACDItem';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { LogApi } from '../../api/LogApi';
import CircularProgress from '@mui/material/CircularProgress';

interface MACDChartProps
{
  chartInterval: string;
  tradingPair: string;
}


export const MACDChart = ({ chartInterval, tradingPair }: MACDChartProps) =>
{

  const [ macdResults, setMACD ] = useState<MACDItem[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {

    getMACD(!macdResults)

  }, [chartInterval])

  const getMACD = async (load: boolean) =>
  {
    if(tradingPair && chartInterval)
    {
      try
      {
        load && setLoading(true)
        const logApi = new LogApi()
        const macd = await logApi.getMACD(tradingPair, chartInterval)
        setMACD(macd)
        load && setLoading(false)

      }
      catch(error)
      {
        console.error("Error getting MACD")
        load && setLoading(false)
      }


    }
  }

  return(
   !loading ? <div style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] MACD Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={450}>
      <LineChart
        width={100}
        height={500}
        data={macdResults || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >

        <YAxis yAxisId="left"  />

        <XAxis dataKey="timestamp" tickFormatter={(ts: string) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={200} />

        
        <Legend formatter={(e) => {
          if(e === "signal") return "Signal"
          if(e === "MACD") return "MACD"
        }} />
        <Line yAxisId="left" type="monotone" dataKey="MACD" stroke="#FBC02D" dot={false} strokeWidth={3} />
        <Line yAxisId="left" type="monotone" dataKey="signal" stroke="#2196F3" dot={false} strokeWidth={2} />

        <Tooltip labelStyle={{ color: "grey"}} labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`} />

      </LineChart>
      </ResponsiveContainer>
    </div> : <div style={styles.loader}><CircularProgress /></div> 

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    fontSize: 10,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}