import { PageSectionItem } from "../../../interface/PageSectionItem";

export const pagesData: { [key: number]: PageSectionItem } = [
  {
    title: "Welcome",
    description: "Get started with AlgoTrader Trading Bot. Follow the steps to get trading in no time!",
    animation: require("../../../animations/person-lottie.json"),
    linkUrl: "",
    linkTitle: undefined,
    linkUrlAlt: undefined,
    linkTitleAlt: undefined
  },
  {
    title: "How to add your API Key?",
    description: "See video link on how to add your Binance API key in the app",
    animation: require("../../../animations/youtube-lottie.json"),
    linkUrl: "https://youtu.be/n2LEik5c7mw",
    linkTitle: "Open YouTube",
    linkUrlAlt: undefined,
    linkTitleAlt: undefined
  },
  {
    title: "Already have your API Key?",
    description: "Head over to settings to add your key",
    animation: require("../../../animations/apikey-lottie.json"),
    linkUrl: `https://app.algo-trader.tech/settings`,
    linkTitle: "Settings",
    linkUrlAlt: undefined,
    linkTitleAlt: undefined
  },
  {
    title: "AlgoTrader Mobile App",
    description: "See profits and change settings on the go", 
    animation: require("../../../animations/mobile-app-lottie.json"), 
    linkUrl: "https://play.google.com/store/apps/details?id=com.tradingbotapp",
    linkTitle: "Google Play",
    linkUrlAlt: "https://apps.apple.com/us/app/algotrader/id6458880865",
    linkTitleAlt: "App Store"
  },
  {
    title: "Setup your strategy",
    description: "Checkout the backtesting section to see what strategy and technical indicators are having the highest win rate in the current market. Get in touch with support if you're having any questions about setup.",
    animation: require("../../../animations/robot-code-lottie.json"),
    linkUrl: `https://app.algo-trader.tech/backtesting`,
    linkTitle: "Backtesting",
    linkUrlAlt: undefined,
    linkTitleAlt: undefined
  }
]

export enum PAGES 
{
  WELCOME,
  API_KEY,
  KEY_ALREADY,
  MOBILE_APP,
  BACKTESTING
}