import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { CheckProfitResponse } from '../../../interface/CheckProfitResponse';
import moment from 'moment';

interface CheckProfitSectionProps
{
  checkProfitResponse: CheckProfitResponse
  lastUpdated?: Date
}

export const CheckProfitSection = ({ checkProfitResponse, lastUpdated }: CheckProfitSectionProps) =>
{


  const getProfitStyle = (profit: number) =>
  {
    try
    {
      if(profit === 0) return styles.profitneutral
      if(profit > 0) return styles.profitPositive
      return styles.profitNegative
    }
    catch(error)
    {
      return {}
    }
  }

  return(
      <List dense>
            <ListItem>
              <ListItemText
                primary={`Current Market Price (USD) ${lastUpdated ? `- Last Updated: ${moment(lastUpdated).format('MMM Do, h:mm:ss a')}` : ''}`}
                secondary={`$${checkProfitResponse.currentMarketPrice}`} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={`Last ${checkProfitResponse?.lastBuy ? "Buy" : "Sell"} Price (USD)`}
                secondary={`$${checkProfitResponse?.lastBuy || checkProfitResponse?.lastSell}`} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Price Difference"
                secondary={`$${checkProfitResponse.priceDifference}`} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Profit Percentage"
                secondaryTypographyProps={{
                  style: getProfitStyle(checkProfitResponse.percent)
                }}
                secondary={`${checkProfitResponse.percent}%`} />
            </ListItem>

            <ListItem>
              <ListItemText
                primary="Profit (USD)"
                secondaryTypographyProps={{
                  style: getProfitStyle(checkProfitResponse.profit)
                }}
                secondary={`$${checkProfitResponse.profit}`} />
            </ListItem>

          
      </List>
  )
}

const styles = {
  profitPositive: {
    backgroundColor: "#4CAF50", borderRadius: 12, padding: 4, width: 80
  },
  profitNegative: {
    backgroundColor: "#EF5350", borderRadius: 12, padding: 4, width: 80
  },
  profitneutral: {
    backgroundColor: "#42A5F5", borderRadius: 12, padding: 4, width: 80

  }
}