import Card from "@mui/material/Card"
import StackedLineChart from '@mui/icons-material/StackedLineChart';
import { Summary } from "../../interface/Summary";
import Skeleton from "@mui/material/Skeleton";
import { User } from "../../interface/User";
import CardHeader from "@mui/material/CardHeader";
import { green } from "@mui/material/colors";

interface TotalAssetsProps
{
  user: User
  loading: boolean
  summary: Summary | undefined
}


export const TotalAssets = ({ loading, summary, user }: TotalAssetsProps) =>
{

  return (
    <Card style={{ height: 118, padding: 16, position: "relative" }} elevation={3}>
    
    { loading ? <>

      <Skeleton height={30} width={30} variant="circular" /> 
      <Skeleton height={20} width="70%" /> 
      <Skeleton height={30} width="100%" /> 

    </>
   
    : <>
    
    <CardHeader 
      titleTypographyProps={{ fontSize: 24 }}
      subheaderTypographyProps={{ fontSize: 16 }}
      avatar={<StackedLineChart fontSize="large"/>} 
      title="Asset Net Worth" 
      subheader={summary && `$${summary?.totalAssets.toFixed(2)} ${user.fundingCurrency}`} >
      
    </CardHeader>

    </>}
  </Card>
  )
}