import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import { amber, green, purple } from "@mui/material/colors"
import Typography from "@mui/material/Typography"
import CurrencyBitcoin from '@mui/icons-material/CurrencyBitcoin';
import MoreVert from '@mui/icons-material/MoreVert';
import { Summary, SummaryItem } from "../../interface/Summary";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { formatPrice } from "../../utils/formatPrice";

interface AssetCardProps
{
    summary: Summary | undefined
    summaryItem: SummaryItem | undefined
    loading: boolean
    assetName: string
}



export const AssetCard = ({ loading, summary, summaryItem, assetName }: AssetCardProps) =>
{
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const getChangePercentColor = () =>
  {
    if(summary && summaryItem && Number(summaryItem?.dailyStats.priceChangePercent) > 0) return "success"
    return "error"
  }

  const color = getChangePercentColor()

  return (
    <Card style={{ height: 250, padding: 16, position: "relative" }} elevation={3}>
    
    { loading ? <>

      <Skeleton height={30} width={30} variant="circular" /> 
      <Skeleton height={20} width="70%" /> 
      <Skeleton height={30} width="100%" /> 

    </>
    : <>
    
    <CurrencyBitcoin fontSize="large" />
    <Typography fontSize={32}>{summaryItem?.free}</Typography>
    <Typography fontSize={24} color="textSecondary">{assetName} eq. ${summaryItem?.estValueInUsd?.toFixed(2)} USDT</Typography>
    <Typography fontSize={16} color="textSecondary">Last Price @ ${formatPrice(Number(summaryItem?.dailyStats.lastPrice))}</Typography>
    <Typography fontSize={16} color="textSecondary">Bid @ ${formatPrice(Number(summaryItem?.dailyStats.bidPrice))}</Typography>
    <Typography fontSize={16} color="textSecondary">High @ ${formatPrice(Number(summaryItem?.dailyStats.highPrice))}</Typography>
    <Typography fontSize={16} color="textSecondary">Low @ ${formatPrice(Number(summaryItem?.dailyStats.lowPrice))}</Typography>

    <div style={{ position: "absolute", top: 16, right: 16 }}>
      <Button size="large" variant="outlined" color={color} sx={{ mr: 1 }} endIcon={<MoreVert />} onClick={(e) => setAnchorEl(e.currentTarget)}>
          Change Percent: {Number(summaryItem?.dailyStats.priceChangePercent)}%
      </Button>
       <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)} >
      <MenuItem onClick={() => setAnchorEl(null)}>Ask Price: ${formatPrice(Number(summaryItem?.dailyStats.askPrice))}</MenuItem>
      <MenuItem onClick={() => setAnchorEl(null)}>Price Change: ${formatPrice(Number(summaryItem?.dailyStats.priceChange))}</MenuItem>
      </Menu>
    </div>

    </>}
  </Card>
  )
}