import { OrderItem } from "../interface/OrderItem";

export const isLimitSell = (order: OrderItem) =>
{
  if(order.isMaker === true && order.isBuyer === false) return true;
  return false
}

export const isLimitBuy = (order: OrderItem) =>
{
  if(order.isMaker === true && order.isBuyer === true) return true;
  return false
}

export const isMarketSell = (order: OrderItem) =>
{
  if(order.isMaker === false && order.isBuyer === false) return true
  return false
}

export const isMarketBuy = (order: OrderItem) =>
{
  if(order.isMaker === false && order.isBuyer === true) return true
  return false
}

export const getOrderType = (order: OrderItem) =>
{
  if(isMarketBuy(order)) return "Market Buy"
  if(isMarketSell(order)) return "Market Sell"
  if(isLimitBuy(order)) return "Limit Bid"
  if(isLimitSell(order)) return "Limit Ask"
}

export const getOrderTypeV2 = (order: OrderItem) =>
  {
    if(isMarketBuy(order)) return "Market"
    if(isMarketSell(order)) return "Market"
    if(isLimitBuy(order)) return "Limit"
    if(isLimitSell(order)) return "Limit"
  }
  
