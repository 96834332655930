import { Line, YAxis, XAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { RSIMCrossOver } from '../../interface/RSIItem';

interface RSICrossOverProps
{
  chartInterval: string;
  tradingPair: string;
  rsiShort: number | undefined;
  rsiMedium: number | undefined;
  rsiLong: number | undefined
}


export const RSICrossOver = ({ chartInterval, tradingPair, rsiShort, rsiMedium, rsiLong }: RSICrossOverProps) =>
{

  const [ rsi, setRSI ] = useState<RSIMCrossOver[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  useEffect(() =>
  {
    getRSI(!rsi)
  }, [chartInterval])

  const getRSI = async (load: boolean) =>
  {
    if(tradingPair && chartInterval)
    {
      const logApi = new LogApi()
      load && setLoading(true)
      const rsi = await logApi.getRSICrossOver(tradingPair, chartInterval, rsiShort || 7, rsiMedium || 14, rsiLong || 20)
      setRSI(rsi)
      load && setLoading(false)
    }

  }

  return(
   !loading ? <div style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] RSI Crossover Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={450}>
      <ComposedChart
        width={100}
        height={500}
        data={rsi || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >

        <YAxis yAxisId="left"  />
        <YAxis yAxisId="right" domain={['dataMin', 'dataMax']} orientation="right" />
        <XAxis dataKey="timestamp" tickFormatter={(ts: string) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100}  />
        
        <Legend formatter={(e) => {
          if(e === "rsiShort") return `RSI ${rsiShort}`
          if(e === "rsiMedium") return `RSI Medium ${rsiMedium}`
          if(e === "rsiLong") return `RSI Long ${rsiLong}`
          if(e === "close") return `Close Price`
        }} />

        <Line yAxisId="left" type="monotone" dataKey="rsiShort" stroke="#03A9F4" dot={false} strokeWidth={2} />
        <Line yAxisId="left" type="monotone" dataKey="rsiMedium" stroke="#7E57C2" dot={false} strokeWidth={3} />
        <Line yAxisId="left" type="monotone" dataKey="rsiLong" stroke="#F06292" dot={false} strokeWidth={3} />
        <Line yAxisId="right" type="monotone" dataKey="close" stroke="#66BB6A" dot={false} strokeWidth={1} />

        <Tooltip
        labelStyle={{ color: "grey" }} 
        labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`}
        formatter={(tick, itemName) => {

          if(itemName === 'close') return `$${tick} USD`

          return tick
          
          }}/>

      </ComposedChart>
      </ResponsiveContainer>
    </div> : <div style={styles.loader}><CircularProgress /></div>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    fontSize: 10,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}