
import { useState } from 'react';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';

// Icons
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import CardIcon from '@mui/icons-material/CreditCard'


// Model
import { Stripe } from 'stripe'
import { User } from '../../interface/User';

// Custom
import { BillingApi } from '../../api/BillingApi';
import { SubscriptionsList } from './lists/SubscriptionsList';
import { CardsList } from './lists/CardsList';
import { ConfirmModal } from '../modals/Confirm/ConfirmModal';
import { AddCardModal } from './AddCardModal';
import { Transition } from '../../animations/Transition';

interface ManageSubscriptionModalProps
{
  open: boolean
  handleClose: () => void
  user: User
}



export const ManageSubscriptionModal = ({ open, handleClose, user }: ManageSubscriptionModalProps) =>
{
  const [ subscriptions, setSubscriptions ] = useState<Stripe.Response<Stripe.ApiList<Stripe.Subscription>> | undefined>()
  const [ paymentMethods, setPaymentMethods ] = useState<Stripe.Response<Stripe.ApiList<Stripe.PaymentMethod>> | undefined>()
  const [ email, setEmail ] = useState<string | undefined>()
  const [ name, setName ] = useState<string | undefined>()
  const [ showAddCardModal, setShowAddCardModal ] = useState(false)

  const [ showConfirmDeleteSubscriptionModal, setShowConfirmDeleteSubscriptionModal ] = useState("")
  const [ showConfirmDeleteCardModal, setShowConfirmDeleteCardModal ] = useState("")

  const [ loading, setLoading ] = useState(false)
  const [ updatingCustomer, setUpdatingCustomer ] = useState(false)


  const updateCustomer = async () =>
  {
    try
    {
      setUpdatingCustomer(true)
      if(email && name) await new BillingApi().updateCustomer(email, name)
      setUpdatingCustomer(false)
    }
    catch(error)
    {
      setUpdatingCustomer(false)
    }
  }

  const getCustomer = async () =>
  {
    const customer = await new BillingApi().getCustomer()
    if(customer?.email && customer?.name)
    {
      setEmail(customer.email)
      setName(customer.name)
    }

  }

  const getSubscriptions = async () =>
  {
    setSubscriptions(await new BillingApi().getSubscriptions())
  }

  const getPaymentMethods = async () =>
  {
    setPaymentMethods(await new BillingApi().getPaymentMethods())
  }

  const removeCard = async (paymentMethodId: string) =>
  {
    await new BillingApi().removeCreditCard(paymentMethodId)
    setShowConfirmDeleteCardModal("")
    await getPaymentMethods()
  }

  const removeSubscription = async (subscriptionId: string) =>
  {
    await new BillingApi().deleteSubscription(subscriptionId)
    setShowConfirmDeleteSubscriptionModal("")
    await getSubscriptions()
  }

  const getData = async () =>
  {
    setLoading(true)
    await getCustomer()
    await getSubscriptions()
    await getPaymentMethods()
    setLoading(false)
  }


  const handleOnEnter = () =>
  {
    getData()
  }

  const handleCloseAddCardModal = async () =>
  {
    setShowAddCardModal(false)
    await getPaymentMethods()

  }

  return(
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      TransitionProps={{
        onEnter: handleOnEnter
      }}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>Manage subscription</DialogTitle>


   { loading ? <div style={styles.loading}><CircularProgress /></div> : <DialogContent >

      <Grid container spacing={2} justifyContent="center" flexDirection="column">

        <Grid item container spacing={2}>

          <Grid item xs={6}>
            <TextField placeholder="Email" fullWidth value={email} helperText="email" onChange={(e) => setEmail(e.target.value)} />
          </Grid>

          <Grid item xs={6}>
            <TextField placeholder="Name" fullWidth value={name} helperText="full name" onChange={(e) => setName(e.target.value)} />
          </Grid>

        </Grid>
     
        <Grid item>
          <SubscriptionsList subscriptions={subscriptions} setShowConfirmDeleteSubscriptionModal={(id) => setShowConfirmDeleteSubscriptionModal(id)} />
        </Grid>
         

        <Grid item>
          <CardsList paymentMethods={paymentMethods} setShowConfirmDeleteCardModal={(id) => setShowConfirmDeleteCardModal(id)} />
        </Grid>

      </Grid>


    </DialogContent> }
    <DialogActions style={{ display: 'flex', justifyContent: 'space-between'}}>
      <Button startIcon={<CloseIcon />} onClick={handleClose}>Close</Button>
      <Button startIcon={<CardIcon />}  onClick={() => setShowAddCardModal(true)}>Add Card</Button>
      <Button disabled={updatingCustomer} startIcon={<SaveIcon />} onClick={updateCustomer}>{updatingCustomer ? "Saving...." : "Update" }</Button>
    </DialogActions>

    <ConfirmModal 
      description="Are you sure you want to remove this card?" 
      title="Confirm card removal"
      id={showConfirmDeleteCardModal}
      open={!!showConfirmDeleteCardModal}
      handleClose={() => setShowConfirmDeleteCardModal("")} 
      onConfirm={(id) => removeCard(id)}/>

    <ConfirmModal
       description="Trading will become inactive" 
       title="Confirm subscription cancellation"
       id={showConfirmDeleteSubscriptionModal} 
       open={!!showConfirmDeleteSubscriptionModal} 
       handleClose={() => setShowConfirmDeleteSubscriptionModal("")} 
       onConfirm={(id) => removeSubscription(id)}/>

   { showAddCardModal && <AddCardModal open={showAddCardModal} handleClose={handleCloseAddCardModal} />}

  </Dialog>
  )
}

const styles = {
  loading: {
    flex: 1,
    display: 'flex' as 'flex',
    justifyContent: 'center',
    padding: 100
  }
}