import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import { OrderItem } from "../../../interface/OrderItem"
import moment from "moment"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import Typography from "@mui/material/Typography"
import { getOrderTypeV2 } from "../../../utils/limitMarket"
import { Divider } from "@mui/material"
import { blue, green } from "@mui/material/colors"

interface OrderItemProps
{
  order: OrderItem
}

export const OrderListItem = ({ order }: OrderItemProps) => {


  const getColor = () => {
    if(orderType === "Limit") return green[500]
    if(orderType === "Market") return blue[500]
  }

  const orderType = getOrderTypeV2(order)
  const color = getColor()

  return (
    <>
    <ListItem>

      <ListItemAvatar>
        <Avatar style={{ fontSize: 10, backgroundColor: color, color: "white" }}>{orderType}</Avatar>
      </ListItemAvatar>

      <ListItemText 
        primary={order.symbol} 
        secondary={`Qty. ${order.qty}`} />
        <ListItemSecondaryAction>
          <Typography style={{ color: color }} >${order.price}</Typography>
          <Typography color="textSecondary" variant="caption" >{moment.unix(order.time / 1000).format("lll")}</Typography>
        </ListItemSecondaryAction>
    </ListItem>
    <Divider />
    </>
  )
}
