import { Line, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, XAxis} from 'recharts';
import Typography from '@mui/material/Typography';

import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { SMAItem } from '../../interface/EMASMA';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';

interface SMAChartProps
{
  chartInterval: string;
  tradingPair: string;
  period: number
}

export const SMAChart = ({ chartInterval, tradingPair, period }: SMAChartProps) =>
{

  const [ sma, setSMA ] = useState<SMAItem[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)


  useEffect(() =>
  {
    getSMA(!sma)
  }, [chartInterval])

  const getSMA = async (load: boolean) =>
  {
    if(tradingPair && chartInterval)
    {
      const logApi = new LogApi()
      load && setLoading(true)
      const sma = await logApi.getSMA(tradingPair, chartInterval, period)
      setSMA(sma)
      load && setLoading(false)
    }

  }

  return(
  !loading ? <div style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] SMA Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={450}>
      <ComposedChart
        width={100}
        height={500}
        data={sma || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis tickFormatter={(tick) => `$${tick}`} domain={['dataMin', 'dataMax']} />
        <XAxis dataKey="timestamp" tickFormatter={(ts: number) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100} />
        
        <Legend formatter={(e) => {
          if(e === "sma") return "SMA period " + period
          if(e === "close") return "Close Price"
        }} />
        <Line type="monotone" dataKey="sma" stroke="#4CAF50" dot={false} strokeWidth={3} />
        <Line type="monotone" dataKey="close" stroke="#FFB300" dot={false} strokeWidth={1} />

        <Tooltip formatter={(tick) => `$${Number(tick).toFixed(2)} USD`} labelStyle={{ color: "grey"}} labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`} />

      </ComposedChart>
      </ResponsiveContainer>
    </div> : <div style={styles.loader}><CircularProgress /></div>
  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 10,
    padding: 12,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}