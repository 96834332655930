import Card from "@mui/material/Card"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { useEffect, useState } from "react"
import { BtcMarkets } from "../../api/BtcMarkets"
import { OrderItem } from "../../interface/OrderItem"
import { OrderListItem } from "./listItems/OrderListItem"
import List from "@mui/material/List"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../interface/ApplicationState"
import { getMarketData } from "../../redux/reducers/grid/gridReducer"
import { MarketListItem } from "../gridbot/list/MarketListItem"
import { LottieLoading } from "../../components/placeholder/LottieLoading"
import { BuySellModal } from "../../components/modals/BuySellModal/BuySellModal"
import { SearchBar } from "../../components/SearchBar"
import Box from "@mui/material/Box"

interface OrdersV2SectionProps
{
  marketId?: string
}


export const OrdersV2Section = ({ marketId }: OrdersV2SectionProps) => {

  const [ orders, setOrders ] = useState<OrderItem[] | undefined>([])
  const [ loading, setLoading ] = useState(false)
  const [ query, setQuery ] = useState("")
  const [ tab, setTab ] = useState<"Bid" | "Ask" | "Trending" | "Gainers" | "Losers">("Gainers")
  const dispatch = useDispatch()
  const markets = useSelector((state: ApplicationState) => state.grid.marketData)
  const isGettingMarkets = useSelector((state: ApplicationState) => state.grid.isGettingMarkets)
  const [ showBuySellModal, setShowBuySellModal ] = useState("")
  const [ side, setSide ] = useState("buy")


    useEffect(() => {

      if(tab === "Gainers" || tab === "Losers" || tab === "Trending")
      {
        dispatch(getMarketData())
        setQuery("")
      }

    }, [ tab ])
  
    useEffect(() => {
  
      if(tab === "Bid" || tab === "Ask") getOrders()
  
    }, [marketId, tab])
  
    const getOrders = async () =>
    {
      try
      {
        if(!marketId) return
        setLoading(true)
        const btc = new BtcMarkets()
        const orders = await btc.getOrders(marketId, tab as any)
    
        setOrders(orders?.reverse())
        setLoading(false)
      }
      catch(error)
      {
        setLoading(false)
      }
  
    }

    const handleShowModal = (pair: string, side: string) =>
    {
      setShowBuySellModal(pair)
      setSide(side)
    }

  return (
    <Card style={{ height: 600 }} elevation={3}>

    <Tabs value={tab} onChange={(_, value) => setTab(value)}>
      <Tab value="Bid" label="Buy Orders"></Tab>
      <Tab value="Ask" label="Sell Orders"></Tab>
      <Tab value="Trending" label="Trending"></Tab>
      <Tab value="Gainers" label="Gainers"></Tab>
      <Tab value="Losers" label="Losers"></Tab>
    </Tabs>

    { (loading || isGettingMarkets ) ? <>
        <LottieLoading animation={require("../../animations/graph-lottie.json")} text={`Getting Data`} height={100} width={100} loop containerHeight="60vh" />
    </> :
      <>
    { tab === "Bid" && <List style={styles.list}>
      { orders?.map((item, key) => <OrderListItem key={key} order={item}/>) }
      { orders && orders.length === 0 && <LottieLoading animation={require("../../animations/not-found-lottie.json")} height={100} width={100} loop={false} text={`No recent buy orders`} containerHeight="50vh"/> }

    </List> }

    { tab === "Ask" && <List style={styles.list}>
      { orders?.map((item, key) => <OrderListItem key={key} order={item}/>) }
      { orders && orders.length === 0 && <LottieLoading animation={require("../../animations/not-found-lottie.json")} height={100} width={100} loop={false} text={`No recent sales`} containerHeight="50vh"/> }

    </List> }

    { tab === "Trending" && <List style={styles.list}>
      <SearchBar placeholder="Search" setQuery={setQuery} size="small" />
      <Box sx={{ mb: 1 }} />
      { markets?.
      filter(item => Number(item.priceChangePercent) > 10 && item.symbol.endsWith("USDT") && item.symbol.toLowerCase().includes(query.toLowerCase()))
      .sort((a,b) => Number(b.priceChangePercent) - Number(a.priceChangePercent))
      .map((item, key) => <MarketListItem showActions key={key} item={item} onClickItem={handleShowModal} />) }
    </List> }

    { tab === "Gainers" && <List style={styles.list}>
    <SearchBar placeholder="Search" setQuery={setQuery} size="small" />
    <Box sx={{ mb: 1 }} />
      { markets?.
      filter(item => Number(item.priceChangePercent) > 5 && Number(item.priceChangePercent) <= 10 && item.symbol.endsWith("USDT") && item.symbol.toLowerCase().includes(query.toLowerCase()))
      .sort((a,b) => Number(b.priceChangePercent) - Number(a.priceChangePercent))
      .map((item, key) => <MarketListItem showActions key={key} item={item} onClickItem={handleShowModal} />) }
    </List> }

    { tab === "Losers" && <List style={styles.list}>
      <SearchBar placeholder="Search" setQuery={setQuery} size="small" />
      <Box sx={{ mb: 1 }} />
      { markets?.
      filter(item => Number(item.priceChangePercent) < 0 && item.symbol.endsWith("USDT") && item.symbol.toLowerCase().includes(query.toLowerCase()))
      .sort((a,b) => Number(a.priceChangePercent) - Number(b.priceChangePercent))
      .map((item, key) => <MarketListItem showActions key={key} item={item} onClickItem={handleShowModal} />) }
    </List> }
     </> }

     { showBuySellModal && <BuySellModal maxBuy={50} side={side as any} waitForBearSignal maxSell={50} open={!!showBuySellModal} handleClose={() => setShowBuySellModal("")} tradingPair={showBuySellModal} /> } 
  </Card>

  
  )
}

const styles = {
  list: {
    overflow: "auto",
    height: "100%"
  }
}