import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { MarketSellResponse } from '../../../../interface/MarketSellResponse';

interface BuyResultSectionProps
{
  buyResponse: MarketSellResponse
}

export const BuyResultSection = ({ buyResponse }: BuyResultSectionProps) =>
{
  return(
    <>
    <Typography color="textSecondary" variant="h6">Buy Response</Typography>

      <List dense>

        <ListItem>
          <ListItemText
            primary="Status"
            secondary={`${buyResponse.order.status}`} />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Amount of Crypto Purchased"
            secondary={`${buyResponse.order.executedQty}`} />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Amount purchased in USD"
            secondary={`$${buyResponse.order.cummulativeQuoteQty}`} />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Profit Percentage"
            secondary={`${buyResponse.runEvent.percentProfit}%`} />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Profit (USD)"
            secondary={`$${buyResponse.runEvent.profit}`} />
        </ListItem>    
  </List>
  </>
  )
}