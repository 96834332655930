import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Candle } from '../../interface/Candle';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useEffect, useState } from 'react';
import { LogApi } from '../../api/LogApi';

interface MarketChartProps
{
  chartInterval: string;
  tradingPair: string;
  height?: number
}


export const MarketChart = ({ chartInterval, tradingPair, height }: MarketChartProps) =>
{

  const [ showClose, setShowClose ] = useState(true)
  const [ showOpen, setShowOpen ] = useState(false)
  const [ showLow, setShowLow ] = useState(false)
  const [ showHigh, setShowHigh ] = useState(false)
  const [ candles, setCandles ] = useState<Candle[] | undefined>(undefined)

  const dataMin = Math.min.apply(null, candles?.map(c => Number(c.close)) || [])
  const dataMax = Math.max.apply(null, candles?.map(c => Number(c.close)) || [])


  useEffect(() => {
    getCandles()

  }, [ chartInterval ])

  const getCandles = async () =>
  {
    if(tradingPair && chartInterval)
    {
      try
      {
        const logApi = new LogApi()
        const candles = await logApi.getCandles(tradingPair, chartInterval)
        setCandles(candles)
      }
      catch(error)
      {
        console.error("Error getting Candles")
      }

    }
  }

  return(
    <div style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] Chart Interval: {chartInterval}</Typography>
    <ResponsiveContainer width="100%" height={height || 450}>
    <LineChart
      data={candles || []}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis dataKey="closeTime" tickFormatter={(ts: number) => moment.unix(ts / 1000).format("DD-MMM hh:mmA")} minTickGap={200} />
      <YAxis yAxisId="left" tickFormatter={(tick) => `$${tick}`} domain={[dataMin, dataMax]} />

      <Legend />
      { showOpen && <Line yAxisId="left" type="monotone" dataKey="open" stroke="#4CAF50"  dot={false} /> }
      { showClose && <Line yAxisId="left" type="monotone" dataKey="close" stroke="#2196F3" dot={false} strokeWidth={3}/> }
      { showLow && <Line yAxisId="left" type="monotone" dataKey="low" stroke="#FF9800"  dot={false} /> }
      { showHigh && <Line yAxisId="left" type="monotone" dataKey="high" stroke="#00897B" dot={false} /> }

      <Tooltip 
        labelStyle={{ color: "grey"}} 
        formatter={(tick) => `$${Number(tick).toFixed(8)} USD`} 
        labelFormatter={(ts) => `${moment.unix(ts / 1000).format("DD-MMM hh:mmA")} - ${moment.unix(ts / 1000).fromNow()}`}/>

    </LineChart>
    </ResponsiveContainer>
    
    <FormControlLabel control={<Switch size="small" checked={showClose} onChange={(e: any) => setShowClose(e.target.checked)} />} label="Close" />
    <FormControlLabel control={<Switch size="small" checked={showOpen} onChange={(e: any) => setShowOpen(e.target.checked)} />} label="Open" />
    <FormControlLabel control={<Switch size="small" checked={showHigh} onChange={(e: any) => setShowHigh(e.target.checked)} />} label="High" />
    <FormControlLabel control={<Switch size="small" checked={showLow} onChange={(e: any) => setShowLow(e.target.checked)} />} label="Low" />

    </div>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    margin: 8,
    fontSize: 10,
    textAlign: 'center' as 'center'
  }
}