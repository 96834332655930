import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../interface/ApplicationState';
import { updateLocalUser, validateApiKey } from '../../redux/actions/userActions';
import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ApiKeyCheckAdornment } from './ApiKeyCheckAdornment';


export const BinanceConnectionSettings = () =>
{
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.userStore.user)
  const theme = useTheme()
  const [ showPass, setShowPass ] = useState(false)

  const isValidatingApiKey = useSelector((state: ApplicationState) => state.userStore.isValidatingApiKey)
  const apiKeyError = useSelector((state: ApplicationState) => state.userStore.apiKeyError)
  const isApiKeyValid = useSelector((state: ApplicationState) => state.userStore.isApiKeyValid)


  const cardBackGroundColor = user.isDarkModeOn ? theme.palette.grey[900] : theme.palette.background.paper



  useEffect(() => {

    dispatch(validateApiKey())

  }, [user.binanceApiKey, user.binanceApiSecret, user.botExchange ])

  return(
    <Card style={{ backgroundColor: cardBackGroundColor, borderRadius: 12, margin: 12, padding: 16 }}>


    <Grid item>
      <TextField
         type={showPass ? "text" : "password"}
         error={!isApiKeyValid && !isValidatingApiKey}
         InputProps={{ startAdornment: <IconButton onClick={() => setShowPass(!showPass)}>{showPass ? <VisibilityOff /> : <Visibility />}</IconButton>, endAdornment: <ApiKeyCheckAdornment isValid={isApiKeyValid} />}} 
         style={{ marginBottom: 16 }} 
         value={user.binanceApiKey} 
         fullWidth 
         placeholder="Binance API Key"
         label={isApiKeyValid ? "Binance API Key" : apiKeyError}
         onChange={(e) => dispatch(updateLocalUser({ binanceApiKey: e.target.value }))} />
    </Grid>

    <Grid item>
      <TextField
        type={showPass ? "text" : "password"}
        error={!isApiKeyValid && !isValidatingApiKey}
        InputProps={{ startAdornment: <IconButton onClick={() => setShowPass(!showPass)}>{showPass ? <VisibilityOff /> : <Visibility />}</IconButton>, endAdornment: <ApiKeyCheckAdornment isValid={isApiKeyValid} />}} 
        style={{ marginBottom: 16 }} 
        value={user.binanceApiSecret} 
        fullWidth 
        label={isApiKeyValid ? "Binance API Secret" : apiKeyError}
        placeholder="Binance API Secret"
        onChange={(e) => dispatch(updateLocalUser({ binanceApiSecret: e.target.value }))}/>
    </Grid>

  </Card>

  )
}