import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";


interface SchedulePieChartProps
{
  schedule4h: number;
  schedule8h: number;
  schedule12h: number;
  schedule24h: number;
  schedule48h: number;
  scheduleWeek: number;
}

export const SchedulePieChart = ({ schedule4h, schedule8h, schedule12h, schedule24h, schedule48h, scheduleWeek }: SchedulePieChartProps) => {


  const data = [
    { name: '4 hour', value: schedule4h },
    { name: '8 hour', value: schedule8h },
    { name: '12 hour', value: schedule12h },
    { name: '24 hour', value: schedule24h },
    { name: '48 hour', value: schedule48h },
    { name: 'Every Week', value: scheduleWeek },

  ];

  const COLORS = ['#E91E63', '#0088FE', '#00C49F', '#FF8042', '#7E57C2', '#FFBB28'];
  const RADIAN = Math.PI / 180;


  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
    <PieChart width={400} height={400}>
    <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={130}
        fill="#8884d8"
        dataKey="value"
      >
    {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        </Pie>
        <Legend />
        <Tooltip />
    </PieChart>
  </ResponsiveContainer>
  )
}