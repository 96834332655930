import { Line, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, XAxis} from 'recharts';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { TripleEma } from '../../interface/EMASMA';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomizedDotSell, CustomizedDotBuy } from '../CustomDot';
import { ObvItem } from '../../interface/ObvItem';

interface ObvChartProps
{
  chartInterval: string;
  tradingPair: string;
  shortPeriod: number;
  longPeriod: number;
}


export const ObvChart = ({ chartInterval, tradingPair, shortPeriod, longPeriod }: ObvChartProps) =>
{

  const [ obv, setObv ] = useState<ObvItem[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)

  useEffect(() =>
  {
    getObv()
  }, [chartInterval])

  const getObv = async () =>
  {
    setLoading(true)
    try
    {
      if(tradingPair && chartInterval)
      {
        const data = await new LogApi().getObv(tradingPair, chartInterval, shortPeriod, longPeriod)
        if(data) setObv(data)
      }
    }
    catch(error)
    {
      return []
    }
    setLoading(false)

  }



  return(
  !loading ? <div style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] On Balance Volume: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={450}>
      <ComposedChart
        width={100}
        height={500}
        data={obv || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <YAxis yAxisId="left" tickFormatter={(tick) => `$${tick}`} domain={['dataMin', 'dataMax']} orientation="left"/> 
        <YAxis yAxisId="right" domain={['dataMin', 'dataMax']} orientation="right" />
        <XAxis dataKey="timestamp" tickFormatter={(ts: number) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100} />

        <Legend formatter={(e) => {
          if(e === "emaShort") return `Short EMA Period (${shortPeriod})`
          if(e === "emaLong") return `Long EMA Period (${longPeriod})`
          if(e === "close") return "Close Price"
          if(e === "volume") return "Volume"


        }} />
        <Line  yAxisId="right" type="monotone" dataKey="emaShort" stroke="#00BCD4" dot={false} strokeWidth={2} />
        <Line  yAxisId="right" type="monotone" dataKey="emaLong" stroke="#7E57C2" dot={false} strokeWidth={3} />
        <Line  yAxisId="right" type="monotone" dataKey="volume" stroke="#00796B" dot={false} strokeWidth={2} />
        <Line  yAxisId="left" type="monotone" dataKey="close" stroke="#FFB300" dot={false} strokeWidth={1} />

        <Tooltip 
          formatter={(tick, itemName, data) => {

            const { emaShort, emaLong } = data.payload

            if(itemName === "close") return `Close Price: ${Number(tick).toFixed(2)}`
            if(itemName === "volume") return `Current Volume: ${Number(tick).toFixed(2)}`
            if(itemName === "emaShort" && emaShort > emaLong) return `Bullish $${Number(tick).toFixed(2)} USDT (Should Buy)`
            if(itemName === "emaLong" && emaShort < emaLong) return `Bearish $${Number(tick).toFixed(2)} USDT (Should Sell)`

            return `$${Number(tick).toFixed(2)} USDT (HOLD)`

          }} 
          labelStyle={{ color: "grey"}} 
          labelFormatter={ts => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`} />

      </ComposedChart>
      </ResponsiveContainer>
    </div> : <div style={styles.loader}><CircularProgress /></div>
  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 10,
    padding: 12,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}