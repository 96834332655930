
import Typography from '@mui/material/Typography'
import CssBaseline from '@mui/material/CssBaseline'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container';

export const Terms = () =>
{


  return(
    <Container>
      <CssBaseline />
      <Card sx={{ p: 1, m: 1, borderRadius: 2 }}>
      <Typography variant="h4" sx={{ mb: 6 }} textAlign="center">
        Terms and Conditions
      </Typography>
      <div >
        <Typography variant="h6" >
          1. Acceptance of Terms and Conditions
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
          By using the trading bot application (the "App"), you agree to be bound by these terms and conditions ("Terms", "Terms and Conditions"). If you do not agree to these Terms and Conditions, you may not use the App.
        </Typography>
      </div>
      <div >
        <Typography variant="h6">
          2. Description of Service
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
          The App is a trading bot application that allows users to connect to their Binance, Gate.io or Kraken account and perform trading activities automatically. The App uses the API provided by Binance, Gate.io or Kraken to connect to the user's account.
        </Typography>
      </div>
      <div >
        <Typography variant="h6">
          3. Use of the App
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
          The App is intended for use by individuals who have knowledge and experience in trading. The user must have a Binance, Gate.io or Kraken account and API key to use the App. The user must also set their own trading strategies, risk management, and parameters. The App does not provide financial advice or recommendations. The user is solely responsible for any trading decisions and outcomes.
        </Typography>
      </div>
      <div >
        <Typography variant="h6" >
          4. Fees
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
          The App charges a fee for the use of the service. The fee structure is available on the App and is subject to change at any time.
        </Typography>
      </div>
      <div >
        <Typography variant="h6">
          5. Risk Disclosure
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
          The user acknowledges that trading activities involve a high level of risk, and may result in loss of funds. The user must ensure that they understand the risks involved and only invest funds they can afford to lose. The App is not responsible for any losses incurred by the user.
        </Typography>
      </div>
      <div >
        <Typography variant="h6">
          6. Warranties and Representations
        </Typography>
        <Typography variant="body1" sx={{ mb: 6 }}>
          The App makes no warranties or representations of any kind, whether express or implied, regarding the App or its use. The App does not guarantee the accuracy or completeness of any information provided by the App.
        </Typography>
      </div>
      </Card>
      </Container>
      
      )
}
