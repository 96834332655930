import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material"
import { Skeleton, IconButton, Tabs, Tab, Box } from "@mui/material"


import Card from "@mui/material/Card"
import { AdxChart } from "../../components/charts/AdxChart"
import { BollingerBandsChart } from "../../components/charts/BollingerBandsChart"
import { CustomChart } from "../../components/charts/CustomChart"
import { EMASMAChart } from "../../components/charts/EMASMAChart"
import { MACDChart } from "../../components/charts/MACDChart"
import { MFIChart } from "../../components/charts/MFIChart"
import { ObvChart } from "../../components/charts/ObvChart"
import { RSIChart } from "../../components/charts/RSIChart"
import { RSICrossOver } from "../../components/charts/RSIMAChart"
import { SMAChart } from "../../components/charts/SMAChart"
import { TripleEmaChart } from "../../components/charts/TripleEmaChart"
import { TripleRSI } from "../../components/charts/TripleRSI"
import { VolumeProfileChart } from "../../components/charts/VolumeProfileChart"
import { VWAPChart } from "../../components/charts/VWAPChart"
import { User } from "../../interface/User"
import MACDChartIcon from '@mui/icons-material/Analytics'
import VolumeProfileIcon from '@mui/icons-material/LineWeight'

import EMAChartIcon from '@mui/icons-material/StackedLineChart'
import SingleEmaIcon from '@mui/icons-material/ShowChart'
import ObvIcon from '@mui/icons-material/StackedBarChart'

import TripleEmaIcon from '@mui/icons-material/MultilineChart'
import RsiIcon from '@mui/icons-material/SsidChart'
import MfiIcon from '@mui/icons-material/WaterfallChart'
import BBIcon from '@mui/icons-material/LegendToggle'
import { useEffect, useState } from "react"
import { MarketChart } from "../../components/charts/MarketChart"
import { Candle } from "../../interface/Candle"
import { styles } from "../Settings"

interface ChartV2SectionProps
{
  loading: boolean
  user: User
}

const KEY_CHART_INDEX = "KEY_CHART_INDEX"

export const ChartV2Section = ({ loading, user }: ChartV2SectionProps) => {

  const [ tabIndex, setTabIndex ] = useState(8)
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
  const handleResize = () => setWidth(window.innerWidth);
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

  useEffect(() => {

    const savedIndex = localStorage.getItem(KEY_CHART_INDEX)
    setTabIndex(Number(savedIndex) || 8)

  }, [ loading ])

  const handleChangeTab = (index: number) => {
    localStorage.setItem(KEY_CHART_INDEX, String(index))
    setTabIndex(index)
  }

  return (
    <Card style={{ height: 600 }} elevation={3}>
     { loading && <>
      <Skeleton height={80} style={{ margin: 2 }} width="75%"/>
      <Skeleton height={100} style={{ margin: 2 }} width="90%" />
      <Skeleton height={400} style={{ margin: 2 }} width="95%" />

     </>}

    { !loading && user && user.tradingPair && user.chartInterval && 
  <>
      <div style={{ flex: 1, display: 'flex', width: width - 200 }}>

    <Tabs value={tabIndex} onChange={(e, v) => handleChangeTab(v)} aria-label="indicators" variant="standard" >
      <Tab label="MACD" value={0} icon={<MACDChartIcon />} iconPosition="start"  />
      <Tab label="EMA Crossover" value={1} icon={<EMAChartIcon />} iconPosition="start"  />
      <Tab label="Bollinger Bands" value={2} icon={<BBIcon />} iconPosition="start"  />
      <Tab label="RSI" value={3} icon={<RsiIcon />} iconPosition="start"  />
      <Tab label="MFI" value={4}  icon={<MfiIcon />} iconPosition="start" />
      <Tab label="SMA" value={5} icon={<SingleEmaIcon />} iconPosition="start" />
      <Tab label="VWAP" value={6} icon={<SingleEmaIcon />} iconPosition='start' />
      <Tab label="Custom" value={7} icon={<MACDChartIcon />} iconPosition="start"  />
      <Tab label="Triple EMA" value={8} icon={<TripleEmaIcon />} iconPosition="start"  />
      <Tab label="RSI Crossover" value={9} icon={<RsiIcon />} iconPosition="start"  />
      <Tab label="ADX" value={10} icon={<SingleEmaIcon />} iconPosition="start"  />
      <Tab label="Triple RSI" value={11} icon={<RsiIcon />} iconPosition="start"  />
      <Tab label="Volume Profile" value={12} icon={<VolumeProfileIcon />} iconPosition='start' />
      <Tab label="OBV" value={13} icon={<ObvIcon />} iconPosition="start"  />
      <Tab label="Market Chart" value={14} icon={<ObvIcon />} iconPosition="start"  />

    </Tabs>
    </div>

  { tabIndex === 0 && <MACDChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} /> }
  { tabIndex === 1 && <EMASMAChart emaType={user.emaType} chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} emaShortPeriod={user.emaShortPeriod || 8} emaLongPeriod={user.emaLongPeriod || 20}/> }
  { tabIndex === 2 && <BollingerBandsChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair}/> }
  { tabIndex === 3 && <RSIChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} rsiThresholdBuy={user.rsiThresholdBuy} rsiThresholdSell={user.rsiThresholdSell} /> }
  { tabIndex === 4 && <MFIChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} mfiThresholdBuy={user.mfiThresholdBuy} mfiThresholdSell={user.mfiThresholdSell} /> }
  { tabIndex === 5 && <SMAChart period={10} chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} /> }
  { tabIndex === 6 && <VWAPChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} /> }
  { tabIndex === 7 && <CustomChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} short={user.tripleEmaShortPeriod || 8} medium={user.tripleEmaMediumPeriod || 13} long={user.tripleEmaLongPeriod || 21}/> }
  { tabIndex === 8 && <TripleEmaChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} emaShortPeriod={user.tripleEmaShortPeriod || 5} emaMediumPeriod={user.tripleEmaMediumPeriod || 8} emaLongPeriod={user.tripleEmaLongPeriod || 13} volumeShort={user.shortVolumeCrossover || 9} volumeLong={user.longVolumeCrossover || 21}/> }
  { tabIndex === 9 && <RSICrossOver chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} rsiShort={user.rsiShort} rsiMedium={user.rsiMedium} rsiLong={user.rsiLong} /> }
  { tabIndex === 10 && <AdxChart period={14} chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair} /> }
  { tabIndex === 11 && <TripleRSI tradingPair={user.tradingPair} /> }
  { tabIndex === 12 && <VolumeProfileChart tradingPair={user.tradingPair} chartInterval={user.chartInterval || "1m"} bars={user.vpNumberOfBars || 14}/> }
  { tabIndex === 13 && <ObvChart tradingPair={user.tradingPair} chartInterval={user.chartInterval || "1m"} longPeriod={user.obvLongPeriod || 21} shortPeriod={user.obvShortPeriod || 9}/> }
  { tabIndex === 14 && <MarketChart chartInterval={user.chartInterval || "1m"} tradingPair={user.tradingPair}/> }


</> }

    
  </Card>
  )
}

const localStyles = {
  tabBox: {
    overflowX: "auto"
  }
}