import { useEffect, useRef, useState } from 'react';

// MUI
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';


import Alert from '@mui/material/Alert';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';

// Icons
import SellIcon from '@mui/icons-material/Sell';

import { CheckProfitResponse } from '../../../../interface/CheckProfitResponse';

// Custom
import { LinearProgress } from '@mui/material';
import { BuyResultSection } from './BuyResultSection';
import { CheckProfitSection } from '../CheckProfitSection';
import { BuyApi } from '../../../../api/BuyApi';
import { MarketBuyResponse } from '../../../../interface/MarketBuyResponse';
import { getSummaryBegin } from '../../../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../../interface/ApplicationState';

const POLL_PROFIT = 5000

interface BuySectionProps
{
  tradingPair: string
  maxBuy?: number
  handleClose: () => void
}

export const BuySection = ({ handleClose, tradingPair, maxBuy }: BuySectionProps) =>
{

  const userTradingPair = useSelector((state: ApplicationState) => state.userStore.user.tradingPair)
  
  const [ amountToBuy, setAmountToBuy ] = useState(maxBuy || 0)
  
  const [ buyLoading, setBuyLoading ] = useState(false)
  const [ checkProfitLoading, setCheckProfitLoading ] = useState(false)

  const [ checkProfitResponse, setCheckProfit ] = useState<CheckProfitResponse | undefined>(undefined)
  const [ buyResponse, setBuyResponse ] = useState<MarketBuyResponse | undefined>(undefined)

  const [ buyError, setBuyError ] = useState("")

  const [ lastUpdated, setLastUpdated ] = useState(new Date())

  const pollRef = useRef<NodeJS.Timeout | undefined>()

  const dispatch = useDispatch()

  useEffect(() => {

    checkProfit(true)
    
    pollProfit()

    return function()
    {
      clearInterval(pollRef.current)
    }

  }, [ amountToBuy ])

  const pollProfit = () =>
  {
    pollRef.current = setInterval(() => {

      checkProfit(false)
      setLastUpdated(new Date())

    }, POLL_PROFIT)
  }

  const checkProfit = async (refresh: boolean) =>
  {
    try
    {
      if(!amountToBuy) return
      refresh && setCheckProfitLoading(true)
      const buyApi = new BuyApi()
      const response = await buyApi.checkProfit(tradingPair, amountToBuy)
      if(response) setCheckProfit(response)
      refresh && setCheckProfitLoading(false)
    }
    catch(error)
    {
      refresh && setCheckProfitLoading(false)
    }

  }

  const doBuy = async () =>
  {
    try
    {
      setBuyLoading(true)
      const buyApi = new BuyApi()
      const response = await buyApi.makeMarketBuy(tradingPair, amountToBuy, false)
      if(response) setBuyResponse(response)
      setBuyLoading(false)
      setBuyError("")
      dispatch(getSummaryBegin(userTradingPair!, false))
      
    }
    catch(error)
    {
      const errorCast = error as any
      setBuyLoading(false)
      setBuyError(getParsedError(errorCast))
    }

  }

  const getParsedError = (error: any) =>
  {
    try
    {
      if(error.message.response.data.error.code === -2010) return "Account has insufficient balance for requested action."
      return "Looks like there was an issue executing the buy order. Maybe try reduce your buy amount to ensure a successful purchase."
    }
    catch(error)
    {
      return "Looks like there was an issue executing the buy. Maybe try reduce your buy amount to ensure a successful purchase."
    }
  }

  return( 
    <>
   
    <DialogContent>

     { !buyResponse && <><DialogContentText id="alert-dialog-slide-description">
        Are you sure you want to buy?
      </DialogContentText>

      { checkProfitLoading && <LinearProgress variant="indeterminate" title="checking buy profit" style={styles.loading} /> }
      { checkProfitResponse && <CheckProfitSection checkProfitResponse={checkProfitResponse} lastUpdated={lastUpdated} />}

      <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
               $
              </InputAdornment> )
          }}
          value={amountToBuy}
          onChange={(e: any) => setAmountToBuy(e.target.value)}
          inputMode="decimal"
          autoFocus
          margin="dense"
          id="amountToBuy"
          label="Amount to buy (USD)"
          type="number"
          fullWidth
          variant="outlined" />
          </> }

    { buyResponse && <BuyResultSection buyResponse={buyResponse} />}

    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>{buyResponse ? "Close" : "Cancel"}</Button>
      <Button disabled={!!buyResponse || buyLoading} startIcon={buyLoading ? <CircularProgress size={24} /> : <SellIcon />} onClick={doBuy}>{buyLoading ? "Placing buy" : "Buy"}</Button>
    </DialogActions>

    <Snackbar open={!!buyError} message={buyError} autoHideDuration={6000} onClose={() => setBuyError("")}>
      <Alert variant='filled' severity="error" > {buyError} </Alert>
    </Snackbar>
    </>
  )
}

const styles = {
  loading: {
    marginTop: 16,
    borderRadius: 12,
  },
  checkbox: {
    marginTop: 12
  }
}