import { Line, YAxis, XAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment'
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { LogApi } from '../../api/LogApi';
import { useState } from 'react';
import { useEffect } from 'react';
import { RSIItem } from '../../interface/RSIItem';
import { useLogs } from '../../hooks/useLogs';
import { LOG_RANGE } from '../../const';
import { CustomizedDotBuy, CustomizedDotSell } from '../CustomDot';

interface RSIChartProps
{
  chartInterval: string;
  tradingPair: string;
  rsiThresholdBuy: number | undefined;
  rsiThresholdSell: number | undefined;
}


export const RSIChart = ({ chartInterval, tradingPair, rsiThresholdBuy, rsiThresholdSell }: RSIChartProps) =>
{

  const [ rsi, setRSI ] = useState<RSIItem[] | undefined>(undefined)
  const [ loading, setLoading ] = useState(false)
  const { getLogs, findLogAtCandle } = useLogs()

  useEffect(() =>
  {
    combineLogsAndChart(!rsi)
  }, [chartInterval])

  const getRSI = async () =>
  {
    try
    {
      if(tradingPair && chartInterval)return  new LogApi().getRSI(tradingPair, chartInterval)

    }
    catch(error)
    {
      return []
    }
    
  }

  const getInfoForIndex = (index: number) =>
  {
    if(rsiThresholdSell && rsiThresholdBuy)
    {
      if(index > rsiThresholdSell) return `${index.toFixed(0)} is overbought (Should sell)`
      if(index < rsiThresholdBuy) return `${index.toFixed(0)} is oversold (Should buy)`
      return `${index.toFixed(0)} is within ${rsiThresholdBuy} ${rsiThresholdSell} range [HOLD]`
    }

    return ""

  }

  const combineLogsAndChart = async (load: boolean) =>
  {
    try
    {
      load && setLoading(true)
      const combinedChart: RSIItem[] = []
      let fromTime = new Date().toISOString();
      const chartData = await getRSI()
      if(chartData)
      {
        fromTime = chartData[0].timestamp;
        await getLogs(fromTime)

        for(const candle of chartData)
        {
          const foundLog = findLogAtCandle(candle)
          if(foundLog && foundLog.didBuy) candle.didBuy = foundLog.lastPrice
          if(foundLog && foundLog.didSell) candle.didSell = foundLog.lastPrice
          combinedChart.push(candle)
        }
      }

      load && setLoading(false)
      setRSI(combinedChart)
    }
    catch(error)
    {
      load && setLoading(false)
    }

  }

  return(
   !loading ? <div style={styles.container}>
    <Typography color="textSecondary">[{tradingPair}] RSI Chart Interval: {chartInterval}</Typography>
      <ResponsiveContainer width="100%" height={450}>
      <ComposedChart
        width={100}
        height={500}
        data={rsi || []}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >

        <YAxis yAxisId="left"  />
        <YAxis yAxisId="right" domain={['dataMin', 'dataMax']} orientation="right" />
        <XAxis dataKey="timestamp" tickFormatter={(ts: string) => moment(ts).format("DD-MMM hh:mmA")} minTickGap={100}  />
        
        <Legend formatter={(e) => {
          if(e === "rsi") return "RSI"
          if(e === "close") return "Close Price"
          if(e === "didSell") return "Sell"
          if(e === "didBuy") return "Buy"
        }} />

        <Line yAxisId="left" type="monotone" dataKey="rsi" stroke="#FBC02D" dot={false} strokeWidth={3} />
        <Line yAxisId="right" type="monotone" dataKey="close" stroke="#66BB6A" dot={false} strokeWidth={2} />
        <Line yAxisId="right" type="monotone" dataKey="didSell" dot={<CustomizedDotSell />}  />
        <Line yAxisId="right" type="monotone" dataKey="didBuy" dot={<CustomizedDotBuy />}  />

        <Tooltip
        labelStyle={{ color: "grey" }} 
        labelFormatter={(ts) => `${moment(ts).format("DD-MMM hh:mmA")} - ${moment(ts).fromNow()}`}
        formatter={(tick, itemName) => {

          if(itemName === "didSell" ) return `Sold at price $${Number(tick).toFixed(2)} USD`
          if(itemName === "didBuy" ) return `Bought at price $${Number(tick).toFixed(2)} USD`
          if(itemName === 'rsi') return getInfoForIndex(Number(tick))
          if(itemName === 'close') return `$${tick} USD`

          return tick
          
          }}/>

      </ComposedChart>
      </ResponsiveContainer>
    </div> : <div style={styles.loader}><CircularProgress /></div>

  )
}


const styles = {
  container: {
    fontFamily: "Roboto, sans-serif",
    padding: 12,
    fontSize: 10,
    margin: 8,
    textAlign: 'center' as 'center'
  },
  loader: {
    height: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}