import { CheckProfitResponse } from "../interface/CheckProfitResponse";
import { MarketBuyResponse } from "../interface/MarketBuyResponse";
import { HttpService } from "./HttpService";

export class BuyApi extends HttpService
{
  checkProfit = async (tradingPair: string, amountToBuy: number): Promise<CheckProfitResponse | undefined> =>
  {
    const response = await this.request<CheckProfitResponse>("GET", `buy/checkProfit?tradingPair=${tradingPair}&amountToBuy=${amountToBuy}`)
    if(response) return response.data
    return undefined
  }

  makeMarketBuy = async (tradingPair: string, amountToBuy: number, waitForBulls: boolean): Promise<MarketBuyResponse | undefined> =>
  {
    const response = await this.request<MarketBuyResponse>("POST", `buy/market?tradingPair=${tradingPair}&amountToBuy=${amountToBuy}&waitForBulls=${waitForBulls}`)
    if(response) return response.data
    return undefined
  }

}