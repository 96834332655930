import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { MarketSellResponse } from '../../../../interface/MarketSellResponse';

interface SellResultSectionProps
{
  sellResponse: MarketSellResponse
}

export const SellResultSection = ({ sellResponse }: SellResultSectionProps) =>
{
  return(
    <>
    <Typography color="textSecondary" variant="h6">Sale Response</Typography>

      <List dense>

        <ListItem>
          <ListItemText
            primary="Status"
            secondary={`${sellResponse.order.status}`} />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Amount of Crypto Sold"
            secondary={`${sellResponse.order.executedQty}`} />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Amount sold in USD"
            secondary={`$${sellResponse.order.cummulativeQuoteQty}`} />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Profit Percentage"
            secondary={`${sellResponse.runEvent.percentProfit}%`} />
        </ListItem>

        <ListItem>
          <ListItemText
            primary="Profit (USD)"
            secondary={`$${sellResponse.runEvent.profit}`} />
        </ListItem>    
  </List>
  </>
  )
}