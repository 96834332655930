export const KEY_TOKEN = "KEY_TOKEN"

export const REFRESH_INTERVAL = 1000 * 60 * 10

export const DEFAULT_TRADING_PAIR = "BTCTUSD"
export const DEFAULT_FUNDING_CURRENCY = "TUSD"

export const MESSAGE_POLL_INTERVAL = 30000
export const MESSAGE_COUNT_POLL_INTERVAL = 60000 * 5

export const DRAWER_SIZE = 200

export const MAX_MULTI_PAIRS = 10

export const LOG_RANGE = 8 // For charting buys and sells

export const BINANCE_TESTNET_ENDPOINT = "https://testnet.binance.vision"
export const BINANCE_COM_ENDPOINT = "https://api.binance.com"
export const BINANCE_US_ENDPOINT = "https://api.binance.us"
export const KRAKEN_BASE_URL = "https://api.kraken.com"

export const dayTrader = [
  { name: "1m", label: "1 minute"},
  { name: "3m", label: "3 minute"},
  { name: "5m", label: "5 minutes"},
  { name: "15m", label: "15 minutes"},
  { name: "30m", label: "30 minutes"},
]

export const swingTrader = [
  { name: "1h", label: "1 hour"},
  { name: "4h", label: "4 hours"},
  { name: "6h", label: "6 hours"},
  { name: "12h", label: "12 hours"},
]

export const positionTrader = [
  { name: "1d", label: "1 day"},
  { name: "1w", label: "1 week"},
  { name: "1M", label: "1 month"},
]

export const getAllTypes = () => [ ...dayTrader, ...swingTrader, ...positionTrader ].map(i => i.name)