
import { useState } from 'react';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

// Icons
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import CheckCircle from '@mui/icons-material/CheckCircle'


// Custom
import { UserDetails } from './UserDetails';
import { SelectPlan } from './SelectPlan';
import { AddCreditCard } from './AddCreditCard';
import { FinishedScreen } from './FinishedScreen';
import { useDispatch, useSelector } from 'react-redux';
import { addCardClear, addSubscriptionClear, createUserClear, resumeSetup, startSetup } from '../../redux/actions/billingActions';
import { User } from '../../interface/User';
import { ApplicationState } from '../../interface/ApplicationState';
import { BillingApi } from '../../api/BillingApi';
import { Transition } from '../../animations/Transition';

interface SubscriptionModalProps
{
  open: boolean
  handleClose: () => void
  user: User
}

export const SubscriptionModal = ({ open, handleClose, user }: SubscriptionModalProps) =>
{
  const dispatch = useDispatch()

  // User
  const [ email, setEmail ] = useState("")
  const [ name, setName ] = useState("")
  // Product
  const [ selectedProductId, setSelectedProductId ] = useState("")

  // Payment details
  const [ cvc, setCVC ] = useState("")
  const [ cardNumber, setCardNumber ] = useState("")
  const [ expiry, setExpiry ] = useState("")

  const addCardError = useSelector((state: ApplicationState) => state.billing.addCardError)
  const isAddingCreditCard = useSelector((state: ApplicationState) => state.billing.isAddingCreditCard)
  const addCardSuccess = useSelector((state: ApplicationState) => state.billing.addCardSuccess)

  const isCreatingUser = useSelector((state: ApplicationState) => state.billing.isCreatingUser)
  const createCustomerError = useSelector((state: ApplicationState) => state.billing.createCustomerError)
  const createCustomerSuccess = useSelector((state: ApplicationState) => state.billing.createCustomerSuccess)

  const isAddingSubscription = useSelector((state: ApplicationState) => state.billing.isAddingSubscription)
  const addSubscriptionSuccess = useSelector((state: ApplicationState) => state.billing.addSubscriptionSuccess)
  const stripeCustomer = useSelector((state: ApplicationState) => state.billing.stripeCustomer)

  const addSubscriptionError = useSelector((state: ApplicationState) => state.billing.addSubscriptionError)

  const setup = () =>
  {
    const expMonth = expiry.split("/")[0].trim()
    const expYear = expiry.split("/")[1].trim()

    // Once we create the user the stripeCustomer is created
    if(!user.stripeCustomerId && !stripeCustomer) dispatch(startSetup(email, name, selectedProductId, cvc, expMonth, expYear, cardNumber))
    if(user.stripeCustomerId || stripeCustomer) dispatch(resumeSetup(selectedProductId, cvc, expMonth, expYear, cardNumber))

  }

  const addCardErrorResponse = () =>
  {
    try
    {
      // @ts-ignore
      return addCardError.message.response.data.error.message
    }
    catch(error)
    {
      return ""
    }
  }

  const createUserErrorResponse = () =>
  {
    try
    {
      // @ts-ignore
      return createCustomerError.message.response.data.error.message
    }
    catch(error)
    {
      return ""
    }
  }

  const addSubscriptionErrorResponse = () =>
  {
    try
    {
      // @ts-ignore
      return addSubscriptionError.message.response.data.error.message
    }
    catch(error)
    {
      return ""
    }
  }

  const areAllInputsValid = () =>
  {
    if(isCreatingUser || isAddingSubscription || isAddingCreditCard) return false
    if(email && name && selectedProductId && cardNumber.length === 19 && expiry.length === 7 && cvc.length === 3) return true
  }

  const getCustomer = async () =>
  {
    const ss = new BillingApi()
    const customerResponse = await ss.getCustomer()
    setEmail(customerResponse?.email || "")
    setName(customerResponse?.name || "")
  }

  const handleOnEnter = async () =>
  {

    dispatch(addCardClear())
    dispatch(createUserClear())
    dispatch(addSubscriptionClear())

    await getCustomer()

  }

  return(
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      TransitionProps={{
        onEnter: handleOnEnter
      }}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
    <DialogTitle>Let's setup your subscription</DialogTitle>
    <DialogContent>

      { !addSubscriptionSuccess && <>
      <UserDetails email={email} name={name} setEmail={setEmail} setName={setName} />
      <SelectPlan selectedProductId={selectedProductId} setSelectedProductId={setSelectedProductId} />
      <AddCreditCard setCardNumber={setCardNumber} cardNumber={cardNumber} cvc={cvc} setCVC={setCVC} expiry={expiry} setExpiry={setExpiry} />
      </> }

      { addSubscriptionSuccess && <FinishedScreen />}

    </DialogContent>
    <DialogActions style={{ display: 'flex', justifyContent: 'space-between'}}>
      <Button startIcon={<CloseIcon />} onClick={handleClose}>Close</Button>
     { !addSubscriptionSuccess && <Button disabled={!areAllInputsValid()} startIcon={(isCreatingUser || isAddingSubscription || isAddingCreditCard) ? <CircularProgress size={16} /> : <AddIcon />} onClick={setup}>Add</Button> }
     { addSubscriptionSuccess && <Button startIcon={<CheckCircle />} onClick={handleClose}>Done</Button>}

    </DialogActions>

    <Snackbar open={!!addCardErrorResponse()} ><Alert severity="error">{addCardErrorResponse()}</Alert></Snackbar>
    <Snackbar open={isAddingCreditCard} ><Alert severity="info">Adding your card...</Alert></Snackbar>
    <Snackbar open={!!addCardSuccess} ><Alert severity="success">Successfully Added Your Card!</Alert></Snackbar>

    <Snackbar open={!!createUserErrorResponse()} ><Alert severity="error">{createUserErrorResponse()}</Alert></Snackbar>
    <Snackbar open={isCreatingUser} ><Alert severity="info">Creating customer...</Alert></Snackbar>
    <Snackbar open={!!createCustomerSuccess} ><Alert severity="success">Successfully Created Customer!</Alert></Snackbar>

    <Snackbar open={!!addSubscriptionErrorResponse()} ><Alert severity="error">{addSubscriptionErrorResponse()}</Alert></Snackbar>
    <Snackbar open={isAddingSubscription} ><Alert severity="info">Adding subscription...</Alert></Snackbar>
    <Snackbar open={!!addSubscriptionSuccess} ><Alert severity="success">Successfully Assigned Subscription!</Alert></Snackbar>

  </Dialog>
  )
}