import { UserActions } from "../../actions/userActions"

const userInitialState = {
  isGettingUserProfile: false,
  updateProfileResult: "",
  isDrawerOpen: false,
  isGettingSymbols: false,
  isGettingSummary: false,
  summary: undefined,
  symbols: [],
  isValidatingApiKey: false,
  apiKeyError: "",
  isApiKeyValid: false,
  user: {
    username: "",
    isDarkModeOn: false,
  }
}

export const userReducer = (state = userInitialState, action: any) =>
{
  switch(action.type)
  {
    case UserActions.GET_USER_PROFILE_BEGIN:
      return {
        ...state,
        isGettingUserProfile: true,
      }
    case UserActions.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isGettingUserProfile: false,
        user: action.data.user
      }
      case UserActions.GET_USER_PROFILE_ERROR:
        return {
          ...state,
          isGettingUserProfile: false,
        }
     case UserActions.SET_USER_PROFILE_BEGIN:
       return { 
         ...state,
         isSettingUserProfile: true,
         updateProfileResult: "",
       }
       case UserActions.SET_USER_PROFILE_SUCCESS:
        return { 
          ...state,
          isSettingUserProfile: false,
          updateProfileResult: "Successfully updated profile",
          user: action.data.user
        }
        case UserActions.SET_USER_PROFILE_ERROR:
          return { 
            ...state,
            isSettingUserProfile: false,
            updateProfileResult: "Looks like there was an issue updating your profile",
          }
      case UserActions.SET_USER_PROFILE_CLEAR:
          return { 
            ...state,
            isSettingUserProfile: false,
            updateProfileResult: "",
          }
    case UserActions.UPDATE_LOCAL_USER:
        return {
          ...state,
          user: { ...state.user, ...action.data.user }
        }
    case UserActions.TOGGLE_DRAWER:
      return {
        ...state,
        isDrawerOpen: action.data.isDrawerOpen
      }
    case UserActions.GET_SYMBOLS_BEGIN:
      return {
        ...state,
        isGettingSymbols: true
      }
    case UserActions.GET_SYMBOLS_SUCCESS:
      return {
        ...state,
        symbols: action.data.symbols,
        isGettingSymbols: false
      }
    case UserActions.GET_SYMBOLS_ERROR:
      return {
        ...state,
        isGettingSymbols: false,
      }
    case UserActions.GET_SUMMARY_BEGIN:
      return {
        ...state,
        isGettingSummary: action.data.progress,
      }
    case UserActions.GET_SUMMARY_SUCCESS:
      return {
        ...state,
        isGettingSummary: false,
        summary: action.data.summary,
      }
    case UserActions.GET_SUMMARY_ERROR:
      return {
        ...state,
        isGettingSummary: false,
      }
    case UserActions.VALIDATE_API_KEY_BEGIN:
      return {
        ...state,
        isValidatingApiKey: true,
        apiKeyError: ""
      }
    case UserActions.VALIDATE_API_KEY_SUCCESS:
      return {
        ...state,
        isValidatingApiKey: false,
        apiKeyError: "",
        isApiKeyValid: true
      }
    case UserActions.VALIDATE_API_KEY_ERROR:
      return {
        ...state,
        isValidatingApiKey: false,
        apiKeyError: action.error,
        isApiKeyValid: false
      }
    default:
      return state
  }
}