import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import { ApplicationState } from "../../../interface/ApplicationState"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { BackTestApi } from "../../../api/BackTestApi"
import moment from "moment"
import { BackTest } from "../../../interface/BackTest"
import { BackTestResultRow } from "../../../Pages/backtesting/BackTestResultRow"
import { BackTestRunEventModal } from "../BackTestRunEventModal/BackTestRunEventModal"
import TableHead from "@mui/material/TableHead"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import { LottieLoading } from "../../placeholder/LottieLoading"

interface ViewResultsByConfigProps
{
  open: boolean
  onClose: () => void
}

export const ViewResultsByConfig = ({ open, onClose }: ViewResultsByConfigProps) => {

  const config = useSelector((state: ApplicationState) => state.backtesting.config)
  const settings = useSelector((state: ApplicationState) => state.backtesting)
  const [ loading, setLoading ] = useState(false)
  const [ fromTime, setFromTime ] = useState(168)
  const [ results, setResults ] = useState<BackTest[]>([])
  const [ showRunEvents, setShowRunEvents ] = useState<BackTest | undefined>(undefined)
  

  useEffect(() => {

    getResults()

  }, [])

  const getResults = async () => {

    if(!settings?._id) return

    setLoading(true)

    try
    {
      const results = await new BackTestApi().getResultsByTestConfigId(settings?._id, "DESC", moment().subtract(fromTime, "hours").toISOString())
      if(results) setResults(results)
    }
    catch(error)
    {

    }

    setLoading(false)
  }

  return (<Dialog open onClose={onClose} maxWidth='xl' fullWidth>
    <DialogTitle>{settings.friendlyName}</DialogTitle>

    <DialogContent>

    { !loading && <TableContainer component={Paper} style={styles.table}>
       <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader >
         <TableHead>
           <TableRow>
             <TableCell style={styles.header}>Run start time</TableCell>
             <TableCell align="right" style={styles.header}>Test Config Name</TableCell>
             <TableCell align="right" style={styles.header}>Trading Pair</TableCell>
             <TableCell align="right" style={styles.header}>Indicator</TableCell>
             <TableCell align="right" style={styles.header}>Win Percentage</TableCell>
             <TableCell align="right" style={styles.header}>Profit</TableCell>
             <TableCell align="right" style={styles.header}>Sales</TableCell>
             <TableCell align="right" style={styles.header}>Purchases</TableCell>
             <TableCell align="right" style={styles.header}>Candle</TableCell>
             <TableCell align="right" style={styles.header}>Settings</TableCell>

           </TableRow>
         </TableHead>
         <TableBody >
         { results && results.map((result, key) => <BackTestResultRow ownTests="yes" row={result} key={key} onShowRunEvents={() => setShowRunEvents(result)}/>)}

         </TableBody>


       </Table>
     </TableContainer> }

     { loading && <LottieLoading animation={require("../../../animations/backtesting-lottie.json")} height={200} width={200} loop text="Getting Backtest Results" containerHeight="50vh"/>}


    </DialogContent>

    <DialogActions>
      <IconButton onClick={onClose}><CloseIcon /></IconButton>
    </DialogActions>

    <BackTestRunEventModal open={!!showRunEvents} handleClose={() => setShowRunEvents(undefined)} backtestItem={showRunEvents}/>
    
  </Dialog>)
}

const styles = {
  header: {
    fontWeight: "700"
  },
  table: {
    height: "50vh",
    marginTop: 16,
    marginBottom: 28,
  }
}