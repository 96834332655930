import { useState } from 'react';

import CssBaseline from "@mui/material/CssBaseline"

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Settings from '@mui/icons-material/Settings';
import Report from '@mui/icons-material/ListAlt';

import Insights from '@mui/icons-material/Insights';
import Group from '@mui/icons-material/Group';
import Stats from '@mui/icons-material/Insights';


import { SystemBacktestingTable } from './backtesting/SystemBacktestingTable';
import { CustomBacktesting } from './customBacktesting/CustomBacktesting';
import { ApplicationState } from '../interface/ApplicationState';
import { useSelector } from 'react-redux';
import { UserBacktestingStatsTab } from './customBacktesting/UserBacktestingStatsTab';

export const Backtesting = () =>
{
  const isAdmin = useSelector((state: ApplicationState) => state.userStore.user.isAdmin)
  const [ tab, setTab ] = useState(0)

  return(
    <div style={{ height: "91vh", overflow: "hidden" }}>

    <CssBaseline />

    <Tabs onChange={(e, value) => setTab(value)} value={tab} sx={{ mb: 2 }}>
      <Tab value={0} iconPosition='start' icon={<Settings />}  label="My custom tests configs"/>
      <Tab value={1} iconPosition='start' icon={<Report />} label="My custom test results"/>
      <Tab value={2} iconPosition='start' icon={<Insights />}  label="System tests" />
      { isAdmin && <Tab value={3} iconPosition='start' icon={<Group />}  label="All user configs" /> }
      { isAdmin && <Tab value={4} iconPosition='start' icon={<Stats />}  label="User config stats" /> }


    </Tabs>

    { tab === 0 && <CustomBacktesting />}
    { tab === 1 && <SystemBacktestingTable ownTests='yes' />}
    { tab === 2 && <SystemBacktestingTable ownTests='no' />}
    { isAdmin && tab === 3 && <CustomBacktesting isAdmin />}
    { isAdmin && tab === 4 && <UserBacktestingStatsTab />}

    
    </div>
  )
}

