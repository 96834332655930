import { useEffect } from 'react';
import { ResponsiveAppBar } from '../components/navigation/ResponsiveAppBar';
import { NavigationDrawer } from '../components/navigation/NavigationDrawer';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material';

import Box from '@mui/material/Box';
import { darkTheme, lightTheme } from '../Theme/theme';

import { Routes, Route } from 'react-router-dom'
import { Logs } from '../Pages/Logs/Logs';

import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../interface/ApplicationState';
import { Settings } from '../Pages/Settings';
import { Profit } from '../Pages/Profit';
import { Backtesting } from '../Pages/Backtesting';
import { Admin } from '../Pages/admin/Admin';
import { Signals } from '../Pages/signals/Signals';
import { ResetPassword } from '../Pages/ResetPassword';
import { Terms } from '../Pages/Terms';
import { Privacy } from '../Pages/Privacy';
import { ChatWidget } from '../components/chat/ChatWidget';
import { SignalsAdmin } from '../Pages/signalStats/SignalsAdmin';
import SmartBanner from 'react-smartbanner';
import { getUserBegin } from '../redux/actions/userActions';
import { GridBotSection } from '../Pages/gridbot/GridBotSection';
import { DashboardV2 } from '../Pages/DashboardV2';

function App() {

  const dispatch = useDispatch();
  const isDarkModeOn = useSelector((state: ApplicationState) => state.userStore.user.isDarkModeOn)
  const isAdmin = useSelector((state: ApplicationState) => state.userStore.user.isAdmin)


  useEffect(() => {

    dispatch(getUserBegin())

  }, [])

  return (
    <ThemeProvider theme={isDarkModeOn ? darkTheme : lightTheme}>

    <SmartBanner title="AlgoTrader" />

    <Box sx={{ display: 'flex', backgroundColor: isDarkModeOn ? "black" : "#eef2f6" }}>

      <ChatWidget />
      
      <ResponsiveAppBar />
        <Toolbar />
      <NavigationDrawer />

      <Box component="main" sx={{ flexGrow: 1, pt: 3, pr: 4 }} >

      <Toolbar />
        
        <Routes>
          <Route path="/" element={<DashboardV2 />} />
          <Route path="/dashboard" element={<DashboardV2 />} />
          <Route path="/gridbot" element={<GridBotSection />} />
          <Route path="/backtesting" element={<Backtesting />} />
          <Route path="/profit" element={<Profit />} />
          <Route path="/signals" element={<Signals />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
         { isAdmin && <Route path="/admin" element={<Admin />} /> } 
         { isAdmin && <Route path="/signalStats" element={<SignalsAdmin />} /> }


        </Routes>
       
        </Box>
    </Box>
    </ThemeProvider>
  );
}

export default App;
