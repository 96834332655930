import { MarketData } from "../../../interface/MarketData"
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { blue, green, red } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../interface/ApplicationState";
import { setMarket } from "../../../redux/reducers/grid/gridReducer";
import { updateLocalUser } from "../../../redux/actions/userActions";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Button from "@mui/material/Button";


interface MarketListItemProps
{
  item: MarketData
  onClickItem?: (pair: string, side: string) => void
  showActions?: boolean
}

export const MarketListItem = ({ item, onClickItem, showActions }: MarketListItemProps) => {

  const dispatch = useDispatch()
  const tradingPair = useSelector((state: ApplicationState) => state.grid.tradingPair)

  const getColor = () => {
    if(Number(item.priceChangePercent)  > 0) return green[500]
    if(Number(item.priceChangePercent)  < 0) return red[500]
    if(Number(item.priceChangePercent) === 0) return blue[400]
  }

  const bgColor = getColor()

  const handleShowModal = (event: any, side: string) => {
    event.stopPropagation()
    event.preventDefault()

    if(onClickItem) onClickItem(item.symbol, side)
  }

  const handleClick = () => {
    dispatch(updateLocalUser({ tradingPair: item.symbol }))
    dispatch(setMarket({ tradingPair: item.symbol }))
  }

  return (
    <ListItem
    disablePadding >
    <ListItemButton role={undefined} onClick={handleClick} dense selected={item.symbol === tradingPair}>
      <Avatar style={{ ...styles.avatar, backgroundColor: bgColor }} >{Number(item.priceChangePercent).toFixed(2)}%</Avatar>
      <ListItemText id={item.symbol} primary={item.symbol} secondary={`Price: ${item.lastPrice} `} />

    { showActions && <ListItemSecondaryAction>
      <Button color="error" onClick={(e: any) => handleShowModal(e, "buy")}>Buy</Button>
      <Button color="success" onClick={(e: any) => handleShowModal(e, "sell")}>Sell</Button>
    </ListItemSecondaryAction> }

    </ListItemButton>

   
  </ListItem>
  )

}

const styles = {
  avatar: {
    marginRight: 12,
    fontSize: 10,
    fontWeight: "700",
    color: "white"
  }
}