import Card from "@mui/material/Card"
import StackedLineChart from '@mui/icons-material/BarChart';
import { BalanceItem, Summary } from "../../interface/Summary";
import Skeleton from "@mui/material/Skeleton";
import { User } from "../../interface/User";
import CardHeader from "@mui/material/CardHeader";
import { DEFAULT_FUNDING_CURRENCY } from "../../const";
import { getBalanceForAsset } from "../../utils/getBalanceForAsset";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MoreVert from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { BuySellModal } from "../../components/modals/BuySellModal/BuySellModal";

interface CurrentFundsProps
{
  user: User
  loading: boolean
  summary: Summary | undefined
}


export const CurrentFunds = ({ loading, summary, user }: CurrentFundsProps) =>
{

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [ showSellModalWithPair, setShowSellModalPair ] = useState("")
  const [ freeAmount, setFreeAmount ] = useState(0)

    const getMaxSell = () =>
    {
      try
      {
        return freeAmount * Number(summary?.prices[showSellModalWithPair])
      }
      catch(error)
      {
        return 0
      }
    }
  
    const handleShowSellModal = (balanceItem: BalanceItem) =>
    { 
      setAnchorEl(null)
      if(!balanceItem.asset.startsWith("USDT"))
      {
        setShowSellModalPair(balanceItem.asset + "USDT")
        setFreeAmount(Number(balanceItem.free))
      }
    }

  return (
    <Card style={{ height: 116, padding: 16, position: "relative" }} elevation={3}>
    
    { loading ? <>

      <Skeleton height={30} width={30} variant="circular" /> 
      <Skeleton height={20} width="70%" /> 
      <Skeleton height={30} width="100%" /> 

    </>
   
    : <>
    
    <CardHeader 
      action={<Button color="info" variant="contained" onClick={(e) => setAnchorEl(e.currentTarget)} endIcon={<MoreVert />}>More Assets</Button>}
      titleTypographyProps={{ fontSize: 24 }}
      subheaderTypographyProps={{ fontSize: 16 }}
      avatar={<StackedLineChart fontSize="large"/>} 
      title={`$${Number(getBalanceForAsset(summary, user.fundingCurrency || DEFAULT_FUNDING_CURRENCY)?.free).toFixed(2)} ${user.fundingCurrency}` } 
      subheader="Current funds for your base pair">
      
    </CardHeader>

    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      >
       {summary?.account?.balances?.filter(balance => Number(balance.free) > 0).map((balance, key) => <MenuItem onClick={() => handleShowSellModal(balance)} key={key}>{balance.asset} | free: {balance.free}</MenuItem>)}

      </Menu>

    </>}

      { !!showSellModalWithPair && <BuySellModal side="sell" waitForBearSignal={false} tradingPair={showSellModalWithPair} open={!!showSellModalWithPair} handleClose={() => setShowSellModalPair("")} maxSell={getMaxSell()} /> }
    
  </Card>
  )
}